/*!

=========================================================
* COVID-19 Dashboard- v1.2.0
=========================================================

* Product Page: http://cphl.go.ug/covid19dashboard
* Copyright 2020 Namutebi Laiton http://cphl.go.ug

* Coded by Namutebi Laiton

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import Covid19 from "./Covid19";
import Ebola from "./Ebola";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";

const Main = () => {
  const [horizontalTabs, sethorizontalTabs] = React.useState("ebola");
  const [verticalTabs, setverticalTabs] = React.useState("ebola");
  const [verticalTabsIcons, setverticalTabsIcons] = React.useState("home");
  const [pageTabs, setpageTabs] = React.useState("home");
  const [openedCollapseOne, setopenedCollapseOne] = React.useState(true);
  const [openedCollapseTwo, setopenedCollapseTwo] = React.useState(false);
  const [openedCollapseThree, setopenedCollapseThree] = React.useState(false);
  // with this function we change the active tab for all the tabs in this page
  const changeActiveTab = (e, tabState, tabName) => {
    e.preventDefault();
    switch (tabState) {
      case "horizontalTabs":
        sethorizontalTabs(tabName);
        break;
      case "verticalTabsIcons":
        setverticalTabsIcons(tabName);
        break;
      case "pageTabs":
        setpageTabs(tabName);
        break;
      case "verticalTabs":
        setverticalTabs(tabName);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <div className="content"  style={{'paddingTop':'65px'}}>
      <Row>
          <Col md="12">
           <Nav className="nav-pills-info" pills>
                  <NavItem>
                    <NavLink
                      data-toggle="tab"
                      href="#pablo"
                      className={horizontalTabs === "ebola" ? "active" : ""}
                      onClick={(e) =>
                        changeActiveTab(e, "horizontalTabs", "ebola")
                      }
                    >
                      Ebola Virus Disease (EVD)
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      data-toggle="tab"
                      href="#pablo"
                      className={horizontalTabs === "covid19" ? "active" : ""}
                      onClick={(e) =>
                        changeActiveTab(e, "horizontalTabs", "covid19")
                      }
                    >
                       Coronavirus disease (COVID-19)                     
                    </NavLink>
                  </NavItem>                 
                </Nav>
                <>
                <Card style={{'background':'transparent'}}>               
              <CardBody style={{'paddingTop':'0px'}}>
                <TabContent className="tab-space" activeTab={horizontalTabs} style={{'paddingTop':'0px'}}>
                  <TabPane tabId="ebola">
                  <Ebola/>                  
                  </TabPane>
                  <TabPane tabId="covid19">
                  <Covid19/>
                  </TabPane>                 
                </TabContent>
                </CardBody>
            </Card>
                </>
          </Col>          
        </Row>        
      </div>
    </>
  );
};

export default Main;