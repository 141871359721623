/*!

=========================================================
* COVID-19 Dashboard- v1.2.0
=========================================================

* Product Page: http://cphl.go.ug/covid19dashboard
* Copyright 2020 Namutebi Laiton http://cphl.go.ug

* Coded by Namutebi Laiton

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import DataHandlerService from "services/DataHandler.js"
//*********Dialog Box */
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


import logo from "assets/img/apple-icon.png"
import { useHistory } from 'react-router';

//import logo2 from "assets/img/cdc.png"

// reactstrap components
import {
  Button,
  Collapse,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  Label,
  FormGroup,
  Form,
  Row,
  Col,
} from "reactstrap";

const AdminNavbar = (props) => {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [color, setColor] = React.useState("navbar-transparent");
  const [username, setUsername] = React.useState("")
  const [usernameState, setUsernameState] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [passwordState, setPasswordState] = React.useState("")
  const [alert, setAlert] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  // const [coordinator, setcoordinator] = React.useState(((localStorage.getItem("loggedin") === 'true') && ((localStorage.getItem("type") === '25') || (localStorage.getItem("type") === '15'))) ? false : true)
  const [coordinator, setcoordinator] = React.useState(() => {
    if (localStorage.getItem("loggedin") === 'true') {
      if ((localStorage.getItem("type") === '25') || (localStorage.getItem("type") === '15')) {
        console.log("cord true")
        return false
      } else {
        console.log("cord false")
        return true
      }
    } else {
      console.log("cord false")
      return true
    }
  })

  // const [facility, setfacility] = React.useState(((localStorage.getItem("loggedin") === 'true') && (localStorage.getItem("type") === '34' || localStorage.getItem("type") === '16' || localStorage.getItem("type") === '39')) ? false : true)
  const [facility, setfacility] = React.useState(() => {
    if ((localStorage.getItem("loggedin") === 'true')) {
      if ((localStorage.getItem("type") === '34') || (localStorage.getItem("type") === '16') || (localStorage.getItem("type") === '39')) {
        return false
      } else {
        return true
      }
    }else{
      return true
    }
  })

  const [loggedout, setLoggedout] = React.useState((localStorage.getItem("loggedin") === 'false') ? true : false)
  const [loggedin, setLoggedin] = React.useState((localStorage.getItem("loggedin") === 'false') ? false : true)
  const [error, setError] = React.useState(true)
  const [errorMsg, seterrorMsg] = React.useState("")
  const routerHistory = useHistory();

  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
  };

  // const isFacilityLoggedIn =()=>{
  //   if((localStorage.getItem("loggedin") === 'true') && (localStorage.getItem("type") === '34' || localStorage.getItem("type") === '16' || localStorage.getItem("type") === '39') ===true){
  //     return false
  //   }else{
  //     return true
  //   }
  // }

  // const isCoordinatorLoggedIn =()=>{
  //   if(localStorage.getItem("loggedin") === 'true') {
  //     if((localStorage.getItem("type") === '25') || (localStorage.getItem("type") === '15')){
  //       console.log("cord true")
  //       return false
  //     }else{
  //       console.log("cord false")
  //       return true
  //     }
  //   }else{
  //     console.log("cord false")
  //     return true
  //   }
  // }
  const hideAlert = () => { setAlert(null) }
  const handleClose = () => {
    setOpen(false)
    setUsername("")
    setPassword("")
  }
  const coordinatorLoggein = () => {
    console.log("i was called")
    if (localStorage.getItem("reflab") === 0) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    //console.log("facility check "+(localStorage.getItem("loggedin")&&(localStorage.getItem("reflab")===34||localStorage.getItem("reflab")===16||localStorage.getItem("reflab")===39)))
    // console.log("Co-[ordinator check"+(localStorage.getItem("loggedin")&&(localStorage.getItem("reflab")==='0')))

    //(localStorage.getItem("loggedin")&&(localStorage.getItem("reflab")===0))

    const interval = setInterval(() => {
      // if(!coordinator || !facility){
      if (localStorage.getItem("token") !== null) {
        DataHandlerService.executeTokenRefreshService()
          .then(response => {
            localStorage.setItem("token", response.data.token)
          })
      }
      //  }
    }, 3420000);//Refresh token after 57mins because it expires at 1 hour 
    return () => clearInterval(interval);
  }, []);

  const handleError = (error) => {
    console.log(JSON.stringify(error));
  }
  const handleLogin = () => {
    console.log(localStorage.getItem("type"))
    let msg = ""
    if (username === "") {
      setUsernameState("has-danger");
      setError(false)
      msg = msg + "Enter username /"
    }
    if (password === "") {
      setPasswordState("has-danger")
      setError(false)
      seterrorMsg("Enter password")
      msg = msg + "Enter password "
    }
    if (username !== "" && password !== "") {
     setError(true)

      DataHandlerService.loginService(username, password)
        .then(response => {
          localStorage.clear()
          localStorage.setItem("token", response.data.token)
          localStorage.setItem("username", response.data.username)
          localStorage.setItem("reflab", response.data.reflab)
          localStorage.setItem("type", response.data.usertype)
          // localStorage.setItem("type", '34')
          localStorage.setItem("loggedin", true)
          if ((localStorage.getItem("type") === '25') || (localStorage.getItem("type") === '15')) {
            // console.log("cord true")
            setcoordinator(false)
          } else {
            console.log("cord false")
            setcoordinator(true)
          }

          if ((localStorage.getItem("loggedin") === 'true')) {
            if ((localStorage.getItem("type") === '34') || (localStorage.getItem("type") === '16') || (localStorage.getItem("type") === '39')) {
              setfacility(false)
            } else {
              setfacility(true)
            }
          }else{
            return true
          }

          setLoggedout(false)
          setLoggedin(true)

          console.log("LOGIN BUTTON CLICK logged-in: "+loggedin+"logged-out: "+loggedout);
          // if (response.data.reflab === 0) {
          //   console.log("cordinator logged in");
          //   setcoordinator(false)
          //   setfacility(true)
          // }
          setOpen(false)
          // console.log("inner" + localStorage.getItem("type"))
          // window.location.reload();

          //Load Study Page
          routerHistory.push('./study')

        })
        .catch(error => handleError(error))
      // console.log(localStorage.getItem("type"))
    }
    seterrorMsg(msg)

  }

  const handleLogout = () => {
    setUsername("")
    setPassword("")
    localStorage.setItem("loggedin", 'false')
    localStorage.setItem("type", '999')//for default user


    DataHandlerService.executeJWTAutheticationService()
      .then(response => {
        // console.log(JSON.stringify(response.data))
        localStorage.setItem("token", response.data.token)
        localStorage.setItem("username", response.data.username)//currently using fixed account
        localStorage.setItem("reflab", response.data.reflab)//reflab   

        setcoordinator(true)
        setfacility(true)
        setLoggedout(true)
        setLoggedin(false)
        console.log("LOGOUT BUTTON CLICK logged-in: "+loggedin+"logged-out: "+loggedout);
        //TO DO: Load Main Page
        routerHistory.push('./')
      })
      .catch(error => handleError(error))
  }

  const openDialog = () => {
    setOpen(true)
    //  alert("here now")

  }
  const handleUsernameChange = (e) => {
    setUsername(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const loadPage = () => {
    ;
  }
  return (
    <>
      <Navbar
        className={classNames("navbar-absolute", {
          [color]: props.location.pathname.indexOf("full-screen-map") === -1,
        })}
        expand="lg" style={{ 'paddingTop': '1px', 'paddingBottom': '1px' }}
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize d-inline">
            </div>
            <div className="logo-img">
              <a href="./"><img src={logo} alt="react-logo" style={{ 'width': '40px' }} /></a>
            </div>
            <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()} style={{ 'color': 'white' }}>
              <h6 style={{ 'margin': '0', 'color': 'white' }}>CENTRAL PUBLIC HEALTH LABORATORIES</h6><h6 style={{ 'margin': '0', 'color': 'white' }}>MINISTRY OF HEALTH</h6> <h6 style={{ 'margin': '0', 'color': 'white' }}>THE REPUBLIC OF UGANDA</h6> </NavbarBrand>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <div className="ml-auto">
              <h4 style={{ 'color': 'white' }}>OUTBREAKS DASHBOARD</h4> </div>
            <Nav className="ml-auto" navbar>
              <InputGroup tag="li" hidden={coordinator}>
                <a href="#" onClick={() => routerHistory.push('./indicators')}> <span className="d-md-block" style={{ 'paddingTop': '10px', 'color': '#fff' }}>Indicators</span>  </a>
              </InputGroup>
              <InputGroup tag="li" hidden={facility}>
                <a href="#" onClick={() => routerHistory.push('./facilityperformance')} > <span className="d-md-block" style={{ 'paddingTop': '10px', 'color': '#fff' }}>Performance</span>  </a>
              </InputGroup>
              <InputGroup tag="li" hidden={loggedin}>
                <i className="tim-icons icon-key-split" class /> <a href="#" onClick={() => openDialog()} ><span className="d-md-block" style={{ 'paddingTop': '10px', 'color': '#fff', 'width': '100px' }}>Login</span></a>
              </InputGroup>
              <InputGroup tag="li" hidden={loggedout}>
                <i className="tim-icons icon-key-split" class /> <a href="#" onClick={() => { routerHistory.push('./'); handleLogout() }} ><span className="d-md-block" style={{ 'paddingTop': '10px', 'color': '#fff', 'width': '100px' }}>Logout</span></a>
              </InputGroup>
              <li className="separator d-lg-none" />
              {/* <img src={logo2} alt="react-logo" style={{ 'width': '50px' }} /> */}
            </Nav>
          </Collapse>
        </Container>
        {alert}
      </Navbar>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title">Login</DialogTitle>
        <DialogContent style={{ 'width': '400px' }}>
          <Form className="form-horizontal">
            <Row>
              <Col md="12" class="danger" hidden={error}>
                <p className="text-danger">
                  {errorMsg}
                </p>
              </Col>
            </Row>
            <Row><Label md="3">Username</Label>
              <Col md="9">
                <FormGroup>
                  <Input value={username} onChange={handleUsernameChange} type="text" style={{ 'border': '1px solid #cad1d7', 'color': '#222a42' }} />
                </FormGroup>
              </Col>
              <Label md="3">Password</Label>
              <Col md="9">
                <FormGroup>
                  <Input value={password} onChange={handlePasswordChange} type="password" style={{ 'border': '1px solid #cad1d7', 'color': '#222a42' }} />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="warning">
            Cancel
          </Button>
          <Button onClick={handleLogin} color="success" >
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdminNavbar;
