/*!

=========================================================
* COVID-19 Dashboard- v1.2.0
=========================================================

* Product Page: http://cphl.go.ug/covid19dashboard
* Copyright 2020 Namutebi Laiton http://cphl.go.ug

* Coded by Namutebi Laiton

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";

import DataHandlerService from "services/DataHandler.js"
import Download from "@material-ui/icons/GetApp";

// react plugin used to create charts
import { HorizontalBar, Bar } from "react-chartjs-2";
import { LoopCircleLoading } from 'react-loadingg';

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Progress,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane
} from "reactstrap";

// react plugin used to create DropdownMenu for selecting items
//import Select from "react-select";
import moment from 'moment';

// react plugin used to create datetimepicker
//import ReactDatetime from "react-datetime";

import Datetime from "react-datetime";
import XLSX from 'xlsx';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Refresh from "@material-ui/icons/Refresh"
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/extendedFormsStyle.js";
import Button2 from "assets/jss/Button.js";

import Search from "@material-ui/icons/Search";

//*********Dialog Box */
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import stylesBtns from "assets/jss/buttonsStyle.js";
import GaugeChart from 'react-gauge-chart'
//Table view
import { MDBDataTable } from 'mdbreact';

// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";


const useStyles = makeStyles(styles);
const useStylesBtns = makeStyles(stylesBtns);

const Ebola = () => {


  const [userActivity, setUserActivity] = React.useState(null);
  const [facilityActivity, setFacilityActivity] = React.useState(null);
  const [horizontalTabs, sethorizontalTabs] = React.useState("totalTests");
  const [singleSelectReg, setsingleSelectReg] = React.useState("");
  const [singleSelectDis, setsingleSelectDis] = React.useState("");
  const [singleSelectHF, setsingleSelectHF] = React.useState("");
  const [singleSelectAge, setsingleSelectAge] = React.useState("");
  const [singleSelectSex, setsingleSelectSex] = React.useState("");
  const [viewByVal, setViewBy] = React.useState(false);
  const [viewTotalByVal, setViewTotalByVal] = React.useState(false);
  const [viewTotalcatByVal, setViewTotalcatByVal] = React.useState(false);
  const [viewTotalPosByVal, setViewTotalPosByVal] = React.useState(false);
  const [loaderCircle, setLoaderCircle] = React.useState(null);
  const [loaderCircle2, setLoaderCircle2] = React.useState(null);
  const [loaderCircle3, setLoaderCircle3] = React.useState(null);
  const [loaderCircle4, setLoaderCircle4] = React.useState(null);
  const [loaderCircle5, setLoaderCircle5] = React.useState(null);
  const [loaderCircle6, setLoaderCircle6] = React.useState(null);
  const [loaderCircle7, setLoaderCircle7] = React.useState(null);
  const [totalTestData, setTotalTestData] = React.useState("");
  const [testCategoryBarData, setTestCategoryData] = React.useState({});
  const [totalPositiveTestData, setTotalPositiveTestData] = React.useState("");
  const [verticalTabs, setverticalTabs] = React.useState("totalTests");
  const [pageTabs, setpageTabs] = React.useState("home");
  const [totalAggTabs, setTotalAggTabs] = React.useState("profile");
  const [open, setOpen] = React.useState(false);
  const [openPositive, setOpenPositive] = React.useState(false);

  const [hideDistrictAgg, setHideDistrictAgg] = React.useState(() => {
    if (localStorage.getItem("loggedin") === 'true') {
      if ((localStorage.getItem("type") === '25') || (localStorage.getItem("type") === '15')) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  });
  const [hideStudySummary, setHideStudySummary] = React.useState(() => {
    if (localStorage.getItem("loggedin") === 'true') {
      if ((localStorage.getItem("type") === '25') || (localStorage.getItem("type") === '15')) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  });
  const [tabledata, setTabledata] = React.useState({
    columns: [
      {
        label: 'id',
        field: 'id',
        sort: 'asc',
        width: 150
      },
      {
        label: 'District     ',
        field: 'district',
        sort: 'asc',
        width: 400
      },
      {
        label: 'Total tests',
        field: 'total',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Positive Tests',
        field: 'positives',
        sort: 'asc',
        width: 150
      },
      {
        label: 'New Positive Tests',
        field: 'newcases',
        sort: 'asc',
        width: 100
      }
    ],
    rows: []
  });
  // const [fromDate, setFromDate] = React.useState(new Date());
  // const [toDate, setToDate] = React.useState(new Date());
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [TotalTestsBarData, setTotalTestsBarData] = React.useState({});
  const [TotalPositiveTestsBarData, setTotalPositiveTestsBarData] = React.useState({});
  const [covidPatientsData, setCovidPatientsData] = React.useState({});
  const [dailyPositiveTestsAgg, setDailyPositiveTestsAgg] = React.useState({});

  const [positiveTestBarData, setPositiveTestBarData] = React.useState({});
  const [positiveTestAgeBarData, setPositiveTestAgeBarData] = React.useState({});
  const [vaccinationInfoData, setVaccinationInfoTabledata] = React.useState({});

  const positiveTestBarOptions = {
    options: {
      responsive: true,
      maintainAspectRatio: false,

      height: 810,
      legend: {
        display: true,
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
      },
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Positive cases'
            },
            gridLines: {
              drawBorder: true,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 10,
              suggestedMax: 30,
              padding: 20,
              fontColor: "#9e9e9e",
              maxTicksLimit: 11
            }, stacked: viewByVal,
            position: 'left',
            id: "yleft"
          },
          {
            scaleLabel: {
              display: true,
              labelString: 'Positivity rate %'
            },
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent",
              display: false
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 100,
              padding: 20,
              fontColor: "#9e9e9e",
            },
            position: "right",
            id: "yright"
          },
        ],
        xAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 10,
              fontColor: "#9e9e9e",
            }, stacked: viewByVal,
          },
        ],
      },
    },

  };

  const positiveTestAgeBarOptions = {
    options: {
      responsive: true,
      maintainAspectRatio: false,

      height: 280,
      legend: {
        display: true,
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
      },
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Positive cases'
            },
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 10,
              suggestedMax: 50,
              padding: 20,
              fontColor: "#9e9e9e",
            },
            position: 'left',
            id: "yleft"
          },
          {
            scaleLabel: {
              display: true,
              labelString: 'Positivity rate %'
            },
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 100,
              padding: 20,
              fontColor: "#9e9e9e",
            },
            position: "right",
            id: "yright"
          },
        ],
        xAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 10,
              fontColor: "#9e9e9e",
            },
          },
        ],
      },
    },
    options2: {
      responsive: true,
      maintainAspectRatio: false,

      height: 410,
      legend: {
        display: true,
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
      },
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Positive cases'
            },
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 10,
              suggestedMax: 200,
              padding: 20,
              fontColor: "#9e9e9e",
            },
            position: 'left',
            id: "yleft", stacked: true
          },
          {
            scaleLabel: {
              display: true,
              labelString: 'Positivity rate'
            },
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 100,
              padding: 20,
              fontColor: "#9e9e9e",
            },
            position: "right",
            id: "yright",
          },
        ],
        xAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 10,
              fontColor: "#9e9e9e",
            }, stacked: true
          },
        ],
      },
    }
  };

  const totalCatTestBarOptions = {
    options: {
      responsive: true,
      maintainAspectRatio: false,

      height: 810,
      legend: {
        display: true,
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
      },
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Positive cases'
            },
            gridLines: {
              drawBorder: true,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 10,
              suggestedMax: 30,
              padding: 20,
              fontColor: "#9e9e9e",
              maxTicksLimit: 11
            }, stacked: viewTotalcatByVal,
            position: 'left',
            id: "yleft"
          },
          {
            scaleLabel: {
              display: true,
              labelString: 'Positivity rate %'
            },
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent",
              display: false
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 100,
              padding: 20,
              fontColor: "#9e9e9e",
            },
            position: "right",
            id: "yright"
          },
        ],
        xAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 10,
              fontColor: "#9e9e9e",
            }, stacked: viewTotalcatByVal,
          },
        ],
      },
    },

  };
  const totalTestBarOptions = {
    options: {
      responsive: true,
      maintainAspectRatio: false,

      height: 810,
      legend: {
        display: true,
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
      },
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Positive cases'
            },
            gridLines: {
              drawBorder: true,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 10,
              suggestedMax: 30,
              padding: 20,
              fontColor: "#9e9e9e",
              maxTicksLimit: 11
            }, stacked: viewTotalByVal,
            position: 'left',
            id: "yleft"
          },
          {
            scaleLabel: {
              display: true,
              labelString: 'Positivity rate %'
            },
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent",
              display: false
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 100,
              padding: 20,
              fontColor: "#9e9e9e",
            },
            position: "right",
            id: "yright"
          },
        ],
        xAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 10,
              fontColor: "#9e9e9e",
            }, stacked: viewTotalByVal,
          },
        ],
      },
    },

  };
  const totalPositiveTestBarOptions = {
    options: {
      responsive: true,
      maintainAspectRatio: false,

      height: 810,
      legend: {
        display: true,
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
      },
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Positive cases'
            },
            gridLines: {
              drawBorder: true,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: 10,
              suggestedMax: 30,
              padding: 20,
              fontColor: "#9e9e9e",
              maxTicksLimit: 11
            }, stacked: viewTotalPosByVal,
            position: 'left',
            id: "yleft"
          },
          {
            scaleLabel: {
              display: true,
              labelString: 'Positivity rate %'
            },
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent",
              display: false
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 100,
              padding: 20,
              fontColor: "#9e9e9e",
            },
            position: "right",
            id: "yright"
          },
        ],
        xAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 10,
              fontColor: "#9e9e9e",
            }, stacked: viewTotalPosByVal,
          },
        ],
      },
    },

  };

  const changeActiveTab = (e, tabState, tabName) => {
    e.preventDefault();
    switch (tabState) {
      case "horizontalTabs":
        sethorizontalTabs(tabName);
        break;
      case "totalAggTabs":
        setTotalAggTabs(tabName);
        break;
      case "pageTabs":
        setpageTabs(tabName);
        break;
      case "verticalTabs":
        setverticalTabs(tabName);
        break;
      default:
        break;
    }
  };


  useEffect(() => {
    //all check if server is reachable    
    if (localStorage.getItem("token") === null || localStorage.getItem("username") === null || localStorage.getItem("type") === null) {
      getToken()
    } else {
      // console.log("User:" +localStorage.getItem("username") + "reflab:"+localStorage.getItem("reflab")+ "loggedin:"+localStorage.getItem("loggedin"))
      DataHandlerService.serverreachable()
        .then(response => {
          getData()
        })
        .catch(error => {
          checktokenexpiry()
          console.log("failed serverreachable" + error)
        })//Checking if any server error with current token

    }
    const interval = setInterval(() => {
      // if(!coordinator || !facility){
      if (localStorage.getItem("loggedin") === 'true') {
        if ((localStorage.getItem("type") === '25') || (localStorage.getItem("type") === '15')) {
          setHideDistrictAgg(true)
          setHideStudySummary(false)
        } else {
          setHideDistrictAgg(false)
          setHideStudySummary(true)
        }
      } else {
        setHideDistrictAgg(false)
        setHideStudySummary(true)
      }
    }, 1000);//Refresh token after 57mins because it expires at 1 hour 
    return () => clearInterval(interval);

  }, [])

  const checktokenexpiry = () => {
    DataHandlerService.executeTokenRefreshService()
      .then(response => {
        //  console.log("Token refreshing:-" + response.data.token)
        localStorage.setItem("token", response.data.token)
        getData()
      }).catch(error => {
        getToken()
        //  console.log("Token refresh failed:-" + error)
      }
      )//If token refresh fails try relogin else server unreachable

  }

  const getToken = () => {
    DataHandlerService.executeJWTAutheticationService()
      .then(response => {
        // console.log(JSON.stringify(response.data))
        localStorage.setItem("token", response.data.token)
        localStorage.setItem("username", response.data.username)//currently using fixed account
        localStorage.setItem("reflab", response.data.reflab)//reflab
        localStorage.setItem("type", '999')//for default login
        localStorage.setItem("loggedin", false)
        getData()
        //  setrefreshTable(randomNum)//Ensures table is refreshed on reload of items   

      })
      .catch(error => handleError(error))
  }

  const getData = () => {
    //.log(moment().subtract(14, 'days').calendar())
    // console.log("No login needed")
    setLoaderCircle(<LoopCircleLoading />)
    setLoaderCircle2(<LoopCircleLoading />)
    setLoaderCircle3(<LoopCircleLoading />)
    setLoaderCircle4(<LoopCircleLoading />)
    setLoaderCircle5(<LoopCircleLoading />)
    setLoaderCircle6(<LoopCircleLoading />)
    // getUserActivityPercentage()
    // getFacilityActivityPercentage()
    getRegions()
    getDistricts()
    getFacilities()
    getDailyEbolaTestAgg()
    getEbolaSummaryAgg()
    getEbolaTestByDistrictAgg()
    // chartAgeBarData()
    // chartBarTotalTestsData()
    // getTestsByDistrictOrFacilityByTestMethod()//Download for Total Tests By Month Agg
    // getPositiveTestsByDistrictOrFacilityByTestMethodAgg()//Download for positive Total Tests By Month Agg
    // chartBarTestsCategoryData()
    // getAllCovidPatients()
  }

  const getAllCovidPatients = () => {
    DataHandlerService.getAllCovidPatients()
      .then(response => {
        //  console.log('Positive ' + JSON.stringify(response.data))
        // console.log('Get all Patients'.JSON.stringify(response.data))
        setCovidPatientsData(JSON.stringify(response.data));
        // setLoaderCircle5(null);
      })
      .catch(error => handleError(error))
  }

  /******************Total Tests/ Positive tests By Month ***********/
  const chartBarTotalTestsData = () => {
    DataHandlerService.getTestsByMonthAgg()
      .then(response => {
        renderTotalTestsBarChart(response.data)
        renderTotalPositiveTestsBarChart(response.data)
      })
      .catch(error => handleError(error))
  }

  const renderTotalTestsBarChart = (data) => {
    let dbTotalPositivity = []
    let dbPCRPositivity = []
    let dbRDTPositivity = []
    let dbPCRTests = []
    let dbRDTTests = []
    let dblabels = []

    data.forEach(item => {
      if (item.totalPositivity === null) {
        dbTotalPositivity.push(0)
      } else {
        dbTotalPositivity.push(item.totalPositivity)
      }
      if (item.pcrTestsPositivity === null) {
        dbPCRPositivity.push(0)
      } else {
        dbPCRPositivity.push(item.pcrTestsPositivity);
      }
      if (item.rdtTestsPositivity === null) {
        dbRDTPositivity.push(0)
      } else {
        dbRDTPositivity.push(item.rdtTestsPositivity);
      }

      dbPCRTests.push(item.pcrTests);
      dbRDTTests.push(item.rdtTests);
      dblabels.push(item.date);
    })

    setTotalTestsBarData(
      {
        labels: dblabels,
        datasets: [
          {
            type: 'line',
            label: "Total Positivity rate",
            fill: true,
            //   backgroundColor: "#FFC300",
            backgroundColor: 'rgba(255, 255, 255, .1)',
            hoverBackgroundColor: " #FFC300",
            borderColor: "#581845",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: dbTotalPositivity,
            yAxisID: "yright",
            lineTension: 0
          },
          {
            type: 'line',
            label: "PCR Positivity rate",
            fill: true,
            //   backgroundColor: "#FFC300",
            backgroundColor: 'rgba(255, 255, 255, .1)',
            hoverBackgroundColor: " #FFC300",
            borderColor: "#DAF7A6",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: dbPCRPositivity,
            yAxisID: "yright",
            lineTension: 0
          },
          {
            type: 'line',
            label: "RDT Positivity rate",
            fill: true,
            //  backgroundColor: "#fff  ",
            backgroundColor: 'rgb(255, 255, 255, .1)',
            hoverBackgroundColor: " #FFC300",
            borderColor: "#C70039",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: dbRDTPositivity,
            yAxisID: "yright",
            lineTension: 0
          },
          {
            label: "PCR tests",
            fill: true,
            backgroundColor: "#FF5733",
            borderColor: "#FF5733",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: dbPCRTests,
            yAxyesID: "yleft"
          },
          {
            label: "RDT tests",
            fill: true,
            backgroundColor: "#FFC300",
            hoverBackgroundColor: " #FFC300",
            borderColor: "#FFC300",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: dbRDTTests,
            yAxisID: "yleft"
          }
        ]
      }
    )
    setLoaderCircle5(null);
  }

  const renderTotalPositiveTestsBarChart = (data) => {
    let dbTotalPositivity = []
    let dbPCRPositivity = []
    let dbRDTPositivity = []
    let dbPCRTests = []
    let dbRDTTests = []
    let dblabels = []

    data.forEach(item => {

      if (item.totalPositivity === null) {
        dbTotalPositivity.push(0)
      } else {
        dbTotalPositivity.push(item.totalPositivity)
      }
      if (item.pcrTestsPositivity === null) {
        dbPCRPositivity.push(0)
      } else {
        dbPCRPositivity.push(item.pcrTestsPositivity);
      }
      if (item.rdtTestsPositivity === null) {
        dbRDTPositivity.push(0)
      } else {
        dbRDTPositivity.push(item.rdtTestsPositivity);
      }

      dbPCRTests.push(item.pcrPositiveTests);
      dbRDTTests.push(item.rdtPositiveTests);
      dblabels.push(item.date);
    })

    setTotalPositiveTestsBarData(
      {
        labels: dblabels,
        datasets: [
          {
            type: 'line',
            label: "Total Positivity rate",
            fill: true,
            //   backgroundColor: "#FFC300",
            backgroundColor: 'rgba(255, 255, 255, .1)',
            hoverBackgroundColor: " #FFC300",
            borderColor: "#581845",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: dbTotalPositivity,
            yAxisID: "yright",
            lineTension: 0
          },
          {
            type: 'line',
            label: "PCR Positivity rate",
            fill: true,
            //   backgroundColor: "#FFC300",
            backgroundColor: 'rgba(255, 255, 255, .1)',
            hoverBackgroundColor: " #FFC300",
            borderColor: "#DAF7A6",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: dbPCRPositivity,
            yAxisID: "yright",
            lineTension: 0
          },
          {
            type: 'line',
            label: "RDT Positivity rate",
            fill: true,
            //  backgroundColor: "#fff  ",
            backgroundColor: 'rgb(255, 255, 255, .1)',
            hoverBackgroundColor: " #FFC300",
            borderColor: "#C70039",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: dbRDTPositivity,
            yAxisID: "yright",
            lineTension: 0
          },
          {
            label: "PCR tests",
            fill: true,
            backgroundColor: "#FF5733",
            borderColor: "#FF5733",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: dbPCRTests,
            yAxyesID: "yleft"
          },
          {
            label: "RDT tests",
            fill: true,
            backgroundColor: "#FFC300",
            hoverBackgroundColor: " #FFC300",
            borderColor: "#FFC300",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: dbRDTTests,
            yAxisID: "yleft"
          }
        ]
      }
    )
    setLoaderCircle5(null);
  }
  //Download
  const getTestsByDistrictOrFacilityByTestMethod = () => {
    DataHandlerService.getTestsByDistrictOrFacilityByTestMethod()
      .then(response => {

        console.log(JSON.stringify(response.data))
        setTotalTestData(JSON.stringify(response.data));
        setLoaderCircle5(null);
        //renderPositiveBarChart(dbTotalPositivity, dbPCRPositivity, dbRDTPositivity, dbPCRPositive, dbRDTPositive, dblabels)
      })
      .catch(error => handleError(error))
  }

  const getPositiveTestsByDistrictOrFacilityByTestMethodAgg = () => {
    DataHandlerService.getPositiveTestsByDistrictOrFacilityByTestMethodAgg()
      .then(response => {

        // console.log(response)
        setTotalPositiveTestData(response.data);
        // setLoaderCircle5(null);
        //renderPositiveBarChart(dbTotalPositivity, dbPCRPositivity, dbRDTPositivity, dbPCRPositive, dbRDTPositive, dblabels)
      })
      .catch(error => handleError(error))
  }

  const chartBarTestsCategoryData = () => {
    let dbHealthWorkers = []
    let dbTravelers = []
    let dbContacts = []
    let dbCases = []
    let dbOthers = []
    let dblabels = []
    DataHandlerService.getTestsByCategoryAgg()
      .then(response => {
        // console.log("Dash Data category" + JSON.stringify(response))
        response.data.forEach(item => {
          dbHealthWorkers.push(item.healthworkers);
          dbTravelers.push(item.traveler);
          dbContacts.push(item.contact);
          dbCases.push(item.case1);
          dbOthers.push(item.other);
          dblabels.push(item.date);
        })
        renderTestsCategoryBarChart(dbHealthWorkers, dbTravelers, dbContacts, dbCases, dbOthers, dblabels)
      })
      .catch(error => handleError(error))

  }

  const renderTestsCategoryBarChart = (dbHealthWorkers, dbTravelers, dbContacts, dbCases, dbOthers, dblabels) => {
    setTestCategoryData(
      {
        labels: dblabels,
        datasets: [
          {
            label: "Health worker",
            fill: true,
            backgroundColor: "#DAF7A6 ",
            borderColor: "#DAF7A6 ",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: dbHealthWorkers,
            yAxyesID: "yleft"
          },
          {
            label: "Traveler",
            fill: true,
            backgroundColor: "#FFC300 ",
            hoverBackgroundColor: "#FFC300 ",
            borderColor: "#FFC300 ",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: dbTravelers,
            yAxisID: "yleft"
          },
          {
            label: "Contact",
            fill: true,
            backgroundColor: "#FF5733",
            hoverBackgroundColor: " #FF5733",
            borderColor: "#FF5733",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: dbContacts,
            yAxisID: "yleft"
          },
          {
            label: "Case",
            fill: true,
            backgroundColor: "#C70039",
            hoverBackgroundColor: " #C70039",
            borderColor: "#C70039",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: dbCases,
            yAxisID: "yleft"
          },
          {
            label: "Other",
            fill: true,
            backgroundColor: "#900C3F",
            hoverBackgroundColor: "#900C3F",
            borderColor: "#900C3F",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: dbOthers,
            yAxisID: "yleft"
          }
        ]
      }

    )
    setLoaderCircle6(null);
  }

  /*****************Daily Positive Tests Aggregates*******************/
  const getDailyEbolaTestAgg = () => {

    DataHandlerService.getDailyEbolaTestAgg()
      .then(response => {
           console.log("Ebola Daily Data:"+response.data)
        renderDailyPositiveTestsAgg(response.data)
      })
      .catch(error => handleError(error))
  }

  const renderDailyPositiveTestsAgg = (data) => {
    //  console.log(data)
    let dbTotalPositivity = []
    let dbPCRPositivity = []
    let dbRDTPositivity = []
    let dbPositive = []
    let dbNegative = []
    let dbTotal = []
    let dblabels = []

    data.forEach(item => {
      if (item.totalPositivity === null) {
        dbTotalPositivity.push(0)
      } else {
        dbTotalPositivity.push(item.testsPositivity)
      }        
      dbPositive.push(item.positives);
      dbNegative.push(item.negatives);
      dbTotal.push(item.totalTests);      
      dblabels.push(moment(item.date).format('Do-MMM-YYYY'));
    })

    setDailyPositiveTestsAgg(
      {
        labels: dblabels,
        datasets: [
          {
            type: 'line',
            label: "Total Positivity rate",
            fill: true,
            //   backgroundColor: "#FFC300",
            backgroundColor: 'rgba(255, 255, 255, .1)',
            hoverBackgroundColor: " #FFC300",
            borderColor: "#581845",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: dbTotalPositivity,
            yAxisID: "yright",
            lineTension: 0
          }
          ,
          {
            label: "Total tests",
            fill: true,
            backgroundColor: "#FFC300",
            hoverBackgroundColor: "#FFC300",
            borderColor: "#FFC300",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: dbTotal,
            yAxyesID: "yleft"
          }
          ,
          {
            label: "Negative tests",
            fill: true,
            backgroundColor: "#DAF7A6",
            hoverBackgroundColor: "#DAF7A63",
            borderColor: "#DAF7A6",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: dbNegative,
            yAxyesID: "yleft"
          },
          {
            label: "Positive tests",
            fill: true,
            backgroundColor: "#FF5730",
            borderColor: "#FF5730",
            hoverBackgroundColor: "#FF5730",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: dbPositive,
            yAxisID: "yleft"
          }
        ]
      }

    )
    setLoaderCircle(null);
  }


  const chartAgeBarData = () => {

    //  let dblabels = []
    DataHandlerService.getTestAggByAgeGrp()
      .then(response => {
        renderPositiveAgeBarChart(response.data)
      })
      .catch(error => handleError(error))

  }

  const renderPositiveAgeBarChart = (data) => {
    let dbPositivity = []
    let dbAgePositive = []
    dbPositivity.push(data.a13yrsPositivity);
    dbAgePositive.push(data.a13yrs);
    dbPositivity.push(data.a18yrsPositivity);
    dbAgePositive.push(data.a18yrs);
    dbPositivity.push(data.a25yrsPositivity);
    dbAgePositive.push(data.a25yrs);
    dbPositivity.push(data.a26yrsPositivity);
    dbAgePositive.push(data.a26);
    dbPositivity.push(data.unKnownPositivity);
    dbAgePositive.push(data.unKnown);

    setPositiveTestAgeBarData(
      {
        labels: ["<=13 years", "14 - 18 years", "19 - 25 years", "> 25 years", "Unknown"],
        datasets: [
          {
            type: 'line',
            label: "Positivity rate",
            fill: true,
            //  backgroundColor: "#fff  ",
            backgroundColor: 'rgb(255, 255, 255, .1)',
            hoverBackgroundColor: " #FFC300",
            borderColor: "#FFC300",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: dbPositivity,
            yAxisID: "yright",
            lineTension: 0
          },
          {
            label: "Positive tests",
            fill: true,
            backgroundColor: "#FF5733",
            hoverBackgroundColor: " #FF5733",
            borderColor: "#FF5733",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: dbAgePositive,
            yAxyesID: "yleft"
          },
        ]
      }
    )
    setLoaderCircle2(null)
  }

  const handleRegionChange = event => {
    setsingleSelectReg(event.target.value);
    // console.log("the Region ID is"+ event.target.value)
    if (event.target.value !== '') { getDistrictsByRegion(event.target.value); }
    else {
      getDistricts()
    }
  };

  const handleDistrictChange = event => {
    setsingleSelectDis(event.target.value);
    if (event.target.value !== '') { getFacilitiesByDistrict(event.target.value) }
    else { getFacilities() }
  };

  const handleAgeGrpChange = event => {
    setsingleSelectAge(event.target.value);
  };

  const handleSexValChange = event => {
    setsingleSelectSex(event.target.value);
  };

  const handleFacilityChange = event => {
    setsingleSelectHF(event.target.value);
  };

  const handleViewChange = event => {
    if (event.target.value === "stacked") {
      setViewBy(true)
    }
    if (event.target.value === "grouped") {
      setViewBy(false)
    }
    // console.log("Selected" + event.target.value)
    // console.log("set" + viewTotalByVal)

    // if (event.target.value === 'totalgrouped ') {
    //   setViewTotalByVal(false)
    // } else if (event.target.value === 'totalstacked') {
    //   setViewTotalByVal(true)
    // }

    // console.log("Selected" + viewTotalByVal)
    // switch (event.target.value) {//totalstacked
    //   case 'stacked':
    //     setViewBy(true)
    //   case 'grouped':
    //     setViewBy(false)
    //   // case 'totalgrouped':
    //   //   console.log("Selected grouped")
    //   //   setViewTotalByVal(false)
    //   // case 'totalstacked':
    //   //   console.log("Selected stacked")
    //   //   setViewTotalByVal(true)
    //   case 'totalcatgrouped':
    //     setViewTotalcatByVal(false)
    //   case 'totalcatstacked':
    //     setViewTotalcatByVal(true)
    //   case 'totalpositivegrouped':
    //     setViewTotalPosByVal(false)
    //   case 'totalpositivestacked':
    //     setViewTotalPosByVal(true)
    //   default:
    //     return null


    // }
    // if (event.target.value === "stacked") {
    //   setViewBy(true)
    // } else {
    //   setViewBy(false)
    // }
    // setSimpleSelect(event.target.value);
  };

  const classes = useStyles();
  const classesBtn = useStylesBtns();
  const yesterday = moment().subtract(1, 'day');
  const valid = function (current) {
    return current.isBefore(yesterday);
  }

  /************Filter by constants**********************/
  const [regions, setRegions] = useState([])
  const [districts, setDistricts] = useState([])
  const [facilities, setFacilities] = useState([])
  const [alert, setAlert] = React.useState(null);

  /************Positive Tests**********************/


  const [totaltests, settotaltests] = useState('-')
  const [healthworker, setHealthworker] = useState('-')

  const [totalpositivetests, settotalpositivetests] = useState('-')
  const [totalpositivehw,settotalpositivehw ] = useState('-')

  const [totalNewpositivetests, settotalNewpositivetests] = useState('-')
  const [totalNewpositivehw, settotalNewpositivehw] = useState('-')

  const [traveler, setTraveler] = useState('-')
  const [totalpcrtests, settotalpcrtests] = useState('-')
  const [totalrdttests, settotalrdttests] = useState('-')

  const [totalpositiveSymptomatic, settotalpositiveSymptomatic] = useState('-')
  const [totalpositivenoSymptom, settotalpositivenoSymptom] = useState('-')
  const [totalpositiveunknownSymptom, settotalpositiveunknownSymptom] = useState('-')

  const [totalNewpositiveSymptomatic, settotalNewpositiveSymptomatic] = useState('-')
  const [totalNewpositivenoSymptom, settotalNewpositivenoSymptom] = useState('-')
  const [totalNewpositiveunknownSymptom, settotalNewpositiveunknownSymptom] = useState('-')

  const [totalVaccinated, setTotalVaccinated] = useState('-')
  const [vaccinated1Dose, setVaccinated1Dose] = useState('-')
  const [vaccinated2Doses, setVaccinated2Doses] = useState('-')
  const [vaccinatedUnKnown, setVaccinatedUnKnown] = useState('-')


  const vaccinationGraph = {
    data: {
      labels: [],
      datasets: [
        {
          label: "Vaccinated",
          fill: true,
          backgroundColor: "#FFC300",
          hoverBackgroundColor: " #FFC300",
          borderColor: "#FFC300",
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          data: [],

        },
        {
          label: "Not vaccinated",
          fill: true,
          backgroundColor: "#FF5733",
          hoverBackgroundColor: " #FF5733",
          borderColor: "#FF5733",
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          data:[],
        },
        {
          label: "Unknown",
          fill: true,
          backgroundColor: "#D7DBDD",
          hoverBackgroundColor: " #D7DBDD",
          borderColor: "#D7DBDD",
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          data: [],
        },
      ],
    },
    options: {
      responsive: false,
      maintainAspectRatio: false,

      // height: 300,
      legend: {
        display: true,
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            // barThickness: 20,
            // barPercentage: 5.5,
            gridLines: {
              drawTicks: false,
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              // suggestedMin: 10,
              // suggestedMax: 110,
              padding: 2,
              fontColor: "#9e9e9e", autoSkip: false
            }, stacked: true,
          },
        ],
        xAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 10,
              fontColor: "#9e9e9e",
            },
            stacked: true,
            position: 'top'
          },
        ],
      },
    },
  };

  //Format numbers with commas
  const numberWithCommas = (x) => {
   // console.log(x)

    if (x !== undefined && parseInt(x, 10) > 999) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else if (x === '0') { return '0' } else if (x === null || x === 'null' ||x === undefined || x === '') { return '-' } else { return x }
  }

  const getEbolaSummaryAgg = async () => {
    DataHandlerService.getEbolaSummaryAgg()
      .then(response => {
           console.log("Ebolasummary" + JSON.stringify(response))
        displayTotalSummaryData(response.data)
        renderEbolaPositiveAgeBarChart(response.data)
      })
      .catch(error => handleError(error))
  }

  const renderEbolaPositiveAgeBarChart = (data) => {
    let dbPositivity = []
    let dbAgePositive = []
   console.log("Data for age grp:"+JSON.stringify(data));
    dbPositivity.push((data.twelveOrLess/data.cumulative)*100);
    dbAgePositive.push(data.twelveOrLess);
    dbPositivity.push((data.thirteenToEighteen/data.cumulative)*100);
    dbAgePositive.push(data.thirteenToEighteen);
    dbPositivity.push((data.nineteenToTwentyfive/data.cumulative)*100);
    dbAgePositive.push(data.nineteenToTwentyfive);
    dbPositivity.push((data.twentysixToThirtyfive/data.cumulative)*100);
    dbAgePositive.push(data.twentysixToThirtyfive);
    dbPositivity.push((data.thirtysixToFortyfive/data.cumulative)*100);
    dbAgePositive.push(data.thirtysixToFortyfive);
    dbPositivity.push((data.fortysixToFiftyfive/data.cumulative)*100);
    dbAgePositive.push(data.fortysixToFiftyfive);
    dbPositivity.push((data.fiftysixOrMore/data.cumulative)*100);
    dbAgePositive.push(data.fiftysixOrMore);

    setPositiveTestAgeBarData(
      {
        labels: ["<=12 years", "13 - 18 years", "19 - 25 years","26 - 35 years", "36 - 45 years","46 - 55 years","> 56 years", "Unknown"],
        datasets: [
          {
            type: 'line',
            label: "Positivity rate",
            fill: true,
            //  backgroundColor: "#fff  ",
            backgroundColor: 'rgb(255, 255, 255, .1)',
            hoverBackgroundColor: " #FFC300",
            borderColor: "#FFC300",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: dbPositivity,
            yAxisID: "yright",
            lineTension: 0
          },
          {
            label: "Positive tests",
            fill: false,
            backgroundColor: "#FF5733",
            hoverBackgroundColor: " #FF5733",            
            borderColor: "#FF5733",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: dbAgePositive,
            yAxyesID: "yleft"
          },
        ]
      }
    )
    setLoaderCircle2(null)
  }


  const getEbolaTestByDistrictAgg = () => {

    let counter = 1
    let tests = []
    DataHandlerService.getEbolaTestByDistrictAgg()
      .then(response => {
         console.log("Tests by District"+JSON.stringify(response.data))
        response.data.forEach(item => {
          
          let itemDetail = [`${item.district}`, numberWithCommas(`${item.totalTests}`), numberWithCommas(`${item.positives}`), numberWithCommas(`${item.newpositives}`)]
          tests.push(itemDetail);
          counter = counter + 1
          // console.log(`Test for table: ${itemDetail}`)
        })
        //  console.log(`Test for table: ${tests}`)
        setTabledata(
          {
            columns: [
              {
                label: 'id',
                field: 'id',
                sort: 'asc',
                width: 150
              },
              {
                label: 'District',
                field: 'district',
                sort: 'asc',
                width: 270
              },
              {
                label: 'Total tests',
                field: 'total',
                sort: 'asc',
                width: 200
              },
              {
                label: 'Positive Tests',
                field: 'positives',
                sort: 'asc',
                width: 100
              },
              {
                label: 'New Positive Tests',
                field: 'newcases',
                sort: 'asc',
                width: 150
              }
            ],
            rows: tests.map((prop, key) => {
              return {
                id: key,
                district: prop[0],
                total: prop[1],
                positives: prop[2],
                newcases: prop[3],
              };
            })
          }
        )
        setLoaderCircle4(null)
      })
      .catch(error => handleError(error))
  }

  const getRegions = () => {
    DataHandlerService.getRegions()
      .then(response => {
        //  console.log("Regions***"+JSON.stringify(response.data))
        let dbRegions = response.data.map(item => {
          return { value: `${item.id}`, display: `${item.region}` }
        });
        setRegions([{ value: '', display: '(None)' }].concat(dbRegions))
      })
      .catch(error => handleError(error))
  }

  const getDistricts = () => {
    DataHandlerService.getDistricts()
      .then(response => {
        let dbDistricts = response.data.map(item => {
          return { value: `${item.id}`, display: `${item.district}` }
        });
        setDistricts([{ value: '', display: '(None)' }].concat(dbDistricts))
      })
      .catch(error => handleError(error))
  }
  const getDistrictsByRegion = (regionid) => {
    //  console.log("the Region ID is " + regionid)
    DataHandlerService.getDistrictsByRegion(regionid)
      .then(response => {
        let dbDistricts = response.data.map(item => {
          return { value: `${item.id}`, display: `${item.district}` }
        });
        //    console.log("the Region Districts are " + JSON.stringify(dbDistricts))
        setDistricts([{ value: '', display: '(None)' }].concat(dbDistricts))
      })
      .catch(error => handleError(error))
  }

  const getFacilities = () => {
    DataHandlerService.getFacilities()
      .then(response => {

        let dbFacilities = response.data.map(item => {
          return { value: `${item.id}`, display: `${item.facility}` }
        });
        // console.log(JSON.stringify(dbFacilities))
        setFacilities([{ value: '', display: '(None)' }].concat(dbFacilities))
      })
      .catch(error => handleError(error))
  }

  const getFacilitiesByDistrict = (districtid) => {
    DataHandlerService.getFacilitiesByDistrict(districtid)
      .then(response => {
        let dbFacilities = response.data.map(item => {
          return { value: `${item.id}`, display: `${item.facility}` }
        });
        setFacilities([{ value: '', display: '(None)' }].concat(dbFacilities))
      })
      .catch(error => handleError(error))
  }

  const handleError = (error) => {
    //TO DO: Show user friendly error
    console.error(error);
    setLoaderCircle(null)
    setLoaderCircle2(null)
    setLoaderCircle3(null)
    setLoaderCircle4(null)
  }

  const warningWithConfirmMessage = (title, msg) => {
    setAlert(

      <ReactBSAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      >{msg}</ReactBSAlert>

    );
  };

  /***************Load Speedometers Data i.e. User Activity and Health Facility Activity*************************/
  const getUserActivityPercentage = () => {
    DataHandlerService.getUserActivityPercentage()
      .then(response => {
        // console.log("getUserActivityPercentage***" + JSON.stringify(response.data))
        setUserActivity(response.data)
      })
      .catch(error => handleError(error))
  }

  const getFacilityActivityPercentage = () => {
    DataHandlerService.getFacilityActivityPercentage()
      .then(response => {
        // console.log("getFacilityActivityPercentage***" + JSON.stringify(response.data))
        setFacilityActivity(response.data)
      })
      .catch(error => handleError(error))
  }

  const getSTDDates = () => {
    let stdFromDate = ""
    let stdToDate = ""
    if (moment(fromDate).format('YYYY-MM-DD') < moment(toDate).format('YYYY-MM-DD')) {
      stdFromDate = moment(fromDate).format('YYYY-MM-DD')
      stdToDate = moment(toDate).format('YYYY-MM-DD')
    } else {
      stdToDate = moment(fromDate).format('YYYY-MM-DD')
      stdFromDate = moment(toDate).format('YYYY-MM-DD')
    }
    return { stdFromDate, stdToDate }
  }

  const getAgeGrpParts = () => {
    let fromAge
    let toAge
    if(singleSelectAge && (typeof singleSelectAge === 'string' || singleSelectAge instanceof String)) {
      const grpParts = singleSelectAge.split('-')
      if (grpParts.length === 2){
        fromAge = grpParts[0]
        toAge = grpParts[1]
      }
    }

    return { fromAge, toAge }
  }

  const getURLParams = () => {
    const stdDates = getSTDDates()
    const ageGrpParts = getAgeGrpParts()
    let params = stdDates.stdFromDate && stdDates.stdToDate ? `fromDate=${stdDates.stdFromDate}&toDate=${stdDates.stdToDate}` : "";
    params += singleSelectReg ? `&regionID=${singleSelectReg}`: "";
    params += singleSelectDis ? `&districtID=${singleSelectDis}`: "";
    params += singleSelectHF ? `&facilityID=${singleSelectHF}`: "";
    params += ageGrpParts.fromAge ? `&fromAge=${ageGrpParts.fromAge}`: "";
    params += ageGrpParts.toAge ? `&toAge=${ageGrpParts.toAge}`: "";
    params += singleSelectSex ? `&sex=${singleSelectSex}`: "";
    return params;
  }

  const setDistrictsDataTable = (data) => {
    let ctr = 1
    let testsData = []
    data.forEach(item => {
      let itemDetail = [`${item.dist1}`, numberWithCommas(`${item.totalTests}`), numberWithCommas(`${item.positiveTests}`), numberWithCommas(`${item.newcases}`)]
      testsData.push(itemDetail);
      ctr = ctr + 1
    })

    setTabledata(
      {
        columns: [
          {
            label: 'id',
            field: 'id',
            sort: 'asc',
            width: 150
          },
          {
            label: 'District',
            field: 'district',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Total tests',
            field: 'total',
            sort: 'asc',
            width: 200
          },
          {
            label: 'Positive Tests',
            field: 'positives',
            sort: 'asc',
            width: 100
          },
          {
            label: 'New Positive Tests',
            field: 'newcases',
            sort: 'asc',
            width: 150
          }
        ],
        rows: testsData.map((prop, key) => {
          return {
            id: key,
            district: prop[0],
            total: prop[1],
            positives: prop[2],
            newcases: prop[3],
          };
        })
      }
    )
    setLoaderCircle4(null)
  }

  const renderVaccinesData = (data) => {
    let vaccData = []
    data.forEach((item, key) => {
      vaccData.push({
        id: key + 1,
        facilityName: item.facilityName,
        totalPositiveTests: (item.vaccinatedPositiveTests + item.notVaccinatedPositiveTests + item.unknownVaccPositiveTests),
        vaccinatedTests: item.vaccinatedPositiveTests,
        notVaccinatedTests: item.notVaccinatedPositiveTests,
        unKnown: item.unknownVaccPositiveTests,
      })
    })

    setVaccinationInfoTabledata(
      {
        columns: [
          {
            label: 'id',
            field: 'id',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Facility',
            field: 'facilityName',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Total positive tests',
            field: 'totalPositiveTests',
            sort: 'asc',
            width: 200
          },
          {
            label: 'Vaccinated Tests',
            field: 'vaccinatedTests',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Not Vaccinated Tests',
            field: 'notVaccinatedTests',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Unknown',
            field: 'unKnown',
            sort: 'asc',
            width: 150
          }
        ],
        rows: vaccData
      }
    )

    setLoaderCircle4(null)
  } 

  /*****************Process Filter By options search *****************************/
  const handleSearch = () => {

    if (fromDate === null || toDate === null) {
      warningWithConfirmMessage("Missing Dates", "Enter the From Date and To Date!");
    } else if (fromDate && toDate) {
      const urlParams = getURLParams()

      setLoaderCircle(<LoopCircleLoading />)
      setLoaderCircle2(<LoopCircleLoading />)
      setLoaderCircle3(<LoopCircleLoading />)

      DataHandlerService.getGeneralTestsSummary(urlParams)
          .then(response => {
            displaySummaryData(response.data)
          })
          .catch(error => handleError(error))

      DataHandlerService.fetchPositiveTestsByAgg(urlParams)
      .then(response => {
        renderDailyPositiveTestsAgg(response.data)
      })
      .catch(error => handleError(error))

      DataHandlerService.fetchTestsByAgeGroup(urlParams)
          .then(response => {
            renderPositiveAgeBarChart(response.data)
          })
          .catch(error => handleError(error))

      DataHandlerService.fetchTestsByDistrict(urlParams)
          .then(response => {
            setDistrictsDataTable(response.data)
          })
          .catch(error => handleError(error))

      DataHandlerService.fetchTestVaccinationByFacilities(urlParams)
          .then(response => {
            renderVaccinesData(response.data)
          })
          .catch(error => handleError(error))

    } else if (fromDate !== null && toDate !== null && singleSelectHF !== null && singleSelectHF !== "") {
      // console.log("HF " + singleSelectHF + "HF end here")
      // console.log("By HF From Date: " + moment(fromDate).format('YYYY-MMM-DD') + " To Date: " + moment(toDate).format('YYYY-MMM-DD') + " For HF" + singleSelectHF)
      let newfromDate = ""
      let newtoDate = ""
      if (fromDate !== null && toDate !== null) {
        if (moment(fromDate).format('YYYY-MM-DD') < moment(toDate).format('YYYY-MM-DD')) {
          newfromDate = moment(fromDate).format('YYYY-MM-DD')
          newtoDate = moment(toDate).format('YYYY-MM-DD')
        } else {
          newtoDate = moment(fromDate).format('YYYY-MM-DD')
          newfromDate = moment(toDate).format('YYYY-MM-DD')
        }

        setLoaderCircle(<LoopCircleLoading />)
        setLoaderCircle2(<LoopCircleLoading />)
        setLoaderCircle3(<LoopCircleLoading />)

        DataHandlerService.getTestsSummaryByDateByFacility(newfromDate, newtoDate, singleSelectHF)
          .then(response => {
            //  console.log("Summary By Facility:" + JSON.stringify(response.data))
            displaySummaryData(response.data)
          })
          .catch(error => handleError(error))

        DataHandlerService.getDailyPositiveTestsAggByDateAndFacility(newfromDate, newtoDate, singleSelectHF)
          .then(response => {
            renderDailyPositiveTestsAgg(response.data)
          })
          .catch(error => handleError(error))

        DataHandlerService.getTestAggByAgeGrpByDateByFacility(newfromDate, newtoDate, singleSelectHF)
          .then(response => {
            renderPositiveAgeBarChart(response.data)
          })
          .catch(error => handleError(error))

      }
    } else if (fromDate !== null && toDate !== null && singleSelectDis !== null && singleSelectDis !== "") {
      // console.log("District " + singleSelectDis + "district end here")
      // console.log("By District From Date: " + moment(fromDate).format('YYYY-MMM-DD') + " To Date: " + moment(toDate).format('YYYY-MMM-DD') + " For District" + singleSelectDis)
      let newfromDate = ""
      let newtoDate = ""
      if (fromDate !== null && toDate !== null) {
        if (moment(fromDate).format('YYYY-MM-DD') < moment(toDate).format('YYYY-MM-DD')) {
          newfromDate = moment(fromDate).format('YYYY-MM-DD')
          newtoDate = moment(toDate).format('YYYY-MM-DD')
        } else {
          newtoDate = moment(fromDate).format('YYYY-MM-DD')
          newfromDate = moment(toDate).format('YYYY-MM-DD')
        }

        setLoaderCircle(<LoopCircleLoading />)
        setLoaderCircle2(<LoopCircleLoading />)
        setLoaderCircle3(<LoopCircleLoading />)

        DataHandlerService.getTestsSummaryByDateByDIstrict(newfromDate, newtoDate, singleSelectDis)
          .then(response => {
            // console.log("Summary By District:" + JSON.stringify(response.data))
            displaySummaryData(response.data)
          })
          .catch(error => handleError(error))

        DataHandlerService.getDailyPositiveTestsAggByDateAndDistrict(newfromDate, newtoDate, singleSelectDis)
          .then(response => {
            renderDailyPositiveTestsAgg(response.data)
          })
          .catch(error => handleError(error))

        DataHandlerService.getTestAggByAgeGrpByDateByDistrict(newfromDate, newtoDate, singleSelectDis)
          .then(response => {
            renderPositiveAgeBarChart(response.data)
          })
          .catch(error => handleError(error))

        let counter = 1
        let tests = []
        DataHandlerService.getTestByFacilityAggByDateByDistrict(newfromDate, newtoDate, singleSelectDis)
          .then(response => {
            response.data.forEach(item => {

              let itemDetail = [`${item.dist1}`, numberWithCommas(`${item.totalTests}`), numberWithCommas(`${item.positiveTests}`), numberWithCommas(`${item.newcases}`)]
              tests.push(itemDetail);
              counter = counter + 1
            })
            setTabledata(
              {
                columns: [
                  {
                    label: 'id',
                    field: 'id',
                    sort: 'asc',
                    width: 150
                  },
                  {
                    label: 'Facility',
                    field: 'district',
                    sort: 'asc',
                    width: 270
                  },
                  {
                    label: 'Total tests',
                    field: 'total',
                    sort: 'asc',
                    width: 200
                  },
                  {
                    label: 'Positive Tests',
                    field: 'positives',
                    sort: 'asc',
                    width: 100
                  },
                  {
                    label: 'New Positive Tests',
                    field: 'newcases',
                    sort: 'asc',
                    width: 150
                  }
                ],
                rows: tests.map((prop, key) => {
                  return {
                    id: key,
                    district: prop[0],
                    total: prop[1],
                    positives: prop[2],
                    newcases: prop[3],
                  };
                })
              }
            )
            setLoaderCircle4(null)
          })
          .catch(error => handleError(error))
      }
    }
    else if (fromDate !== null && toDate !== null && singleSelectReg !== null && singleSelectReg !== "") {
      //  console.log("Region " + singleSelectReg + "region end here")
      //  console.log("By region From Date: " + moment(fromDate).format('YYYY-MMM-DD') + " To Date: " + moment(toDate).format('YYYY-MMM-DD') + " For Region" + singleSelectReg)
      let newfromDate = ""
      let newtoDate = ""
      if (fromDate !== null && toDate !== null) {
        if (moment(fromDate).format('YYYY-MM-DD') < moment(toDate).format('YYYY-MM-DD')) {
          newfromDate = moment(fromDate).format('YYYY-MM-DD')
          newtoDate = moment(toDate).format('YYYY-MM-DD')
        } else {
          newtoDate = moment(fromDate).format('YYYY-MM-DD')
          newfromDate = moment(toDate).format('YYYY-MM-DD')
        }

        setLoaderCircle(<LoopCircleLoading />)
        setLoaderCircle2(<LoopCircleLoading />)
        setLoaderCircle3(<LoopCircleLoading />)
        DataHandlerService.getTestsSummaryByDateByRegion(newfromDate, newtoDate, singleSelectReg)
          .then(response => {
            //  console.log("Summary By Region:" + JSON.stringify(response.data))
            displaySummaryData(response.data)
          })
          .catch(error => handleError(error))

        DataHandlerService.getDailyPositiveTestsAggByDateAndRegion(newfromDate, newtoDate, singleSelectReg)
          .then(response => {
            renderDailyPositiveTestsAgg(response.data)
          })
          .catch(error => handleError(error))

        let dbPositivity = []
        let dbAgePositive = []
        DataHandlerService.getTestAggByAgeGrpByDateByRegion(newfromDate, newtoDate, singleSelectReg)
          .then(response => {
            renderPositiveAgeBarChart(response.data)
          })
          .catch(error => handleError(error))

        let counter = 1
        let tests = []
        DataHandlerService.getTestByDistrictAggByDateByRegion(newfromDate, newtoDate, singleSelectReg)
          .then(response => {
            // console.log(JSON.stringify(response.data))
            response.data.forEach(item => {

              let itemDetail = [`${item.dist1}`, numberWithCommas(`${item.totalTests}`), numberWithCommas(`${item.positiveTests}`), numberWithCommas(`${item.newcases}`)]
              tests.push(itemDetail);
              counter = counter + 1
            })

            setTabledata(
              {
                columns: [
                  {
                    label: 'id',
                    field: 'id',
                    sort: 'asc',
                    width: 150
                  },
                  {
                    label: 'District',
                    field: 'district',
                    sort: 'asc',
                    width: 270
                  },
                  {
                    label: 'Total tests',
                    field: 'total',
                    sort: 'asc',
                    width: 200
                  },
                  {
                    label: 'Positive Tests',
                    field: 'positives',
                    sort: 'asc',
                    width: 100
                  },
                  {
                    label: 'New Positive Tests',
                    field: 'newcases',
                    sort: 'asc',
                    width: 150
                  }
                ],
                rows: tests.map((prop, key) => {
                  return {
                    id: key,
                    district: prop[0],
                    total: prop[1],
                    positives: prop[2],
                    newcases: prop[3],
                  };
                })
              }
            )
            setLoaderCircle4(null)
          })
          .catch(error => handleError(error))
      }
    } else {
      //   console.log("From Date: " + moment(fromDate).format('YYYY-MMM-DD') + " To Date: " + moment(toDate).format('YYYY-MMM-DD'))
      let newfromDate = ""
      let newtoDate = ""
      if (fromDate !== null && toDate !== null) {
        if (moment(fromDate).format('YYYY-MM-DD') < moment(toDate).format('YYYY-MM-DD')) {
          newfromDate = moment(fromDate).format('YYYY-MM-DD')
          newtoDate = moment(toDate).format('YYYY-MM-DD')
        } else {
          newtoDate = moment(fromDate).format('YYYY-MM-DD')
          newfromDate = moment(toDate).format('YYYY-MM-DD')
        }

        setLoaderCircle(<LoopCircleLoading />)
        setLoaderCircle2(<LoopCircleLoading />)
        setLoaderCircle3(<LoopCircleLoading />)

        DataHandlerService.getTestsSummaryByDate(newfromDate, newtoDate)
          .then(response => {
            //   console.log("Summary By date:" + JSON.stringify(response.data))
            displaySummaryData(response.data)
          })
          .catch(error => handleError(error))

        DataHandlerService.getDailyPositiveTestsAggByDate(newfromDate, newtoDate)
          .then(response => {
            // console.log(response.data)
            renderDailyPositiveTestsAgg(response.data)
          })
          .catch(error => handleError(error))

        DataHandlerService.getTestAggByAgeGrpByDate(newfromDate, newtoDate)
          .then(response => {
            // console.log('Back from AgeByDate')
            // console.log(response)
            renderPositiveAgeBarChart(response.data)
          })
          .catch(error => {
            //  console.log('Laiton Error AgeByDate:'.error.toString)
            handleError(error)
          })

        let counter = 1
        let tests = []
        DataHandlerService.getTestByDistrictAggByDate(newfromDate, newtoDate)
          .then(response => {
            // console.log(JSON.stringify(response.data))
            response.data.forEach(item => {

              let itemDetail = [`${item.dist1}`, numberWithCommas(`${item.totalTests}`), numberWithCommas(`${item.positiveTests}`), numberWithCommas(`${item.newcases}`)]
              tests.push(itemDetail);
              counter = counter + 1
            })
            setTabledata(
              {
                columns: [
                  {
                    label: 'id',
                    field: 'id',
                    sort: 'asc',
                    width: 150
                  },
                  {
                    label: 'Facility',
                    field: 'district',
                    sort: 'asc',
                    width: 270
                  },
                  {
                    label: 'Total tests',
                    field: 'total',
                    sort: 'asc',
                    width: 200
                  },
                  {
                    label: 'Positive Tests',
                    field: 'positives',
                    sort: 'asc',
                    width: 100
                  },
                  {
                    label: 'New Positive Tests',
                    field: 'newcases',
                    sort: 'asc',
                    width: 150
                  }
                ],
                rows: tests.map((prop, key) => {
                  return {
                    id: key,
                    district: prop[0],
                    total: prop[1],
                    positives: prop[2],
                    newcases: prop[3],
                  };
                })
              }
            )
            setLoaderCircle4(null)
          })
          .catch(error => handleError(error))
      }

    }
  }

  const displayTotalSummaryData = (data) => {
    settotaltests(numberWithCommas(data.totalTest))
    setHealthworker(numberWithCommas(data.totalHw))

    settotalpositivetests(numberWithCommas(data.cumulative))
    settotalpositivehw(numberWithCommas(data.cumulativeHw))

    settotalNewpositivetests(numberWithCommas(data.newPositive))
    settotalNewpositivehw(numberWithCommas(data.newHw))

    setLoaderCircle3(null)
  }

  const displaySummaryData = (data) => {
    settotaltests(numberWithCommas(data.totaltests))
    settotalpcrtests(numberWithCommas(data.totalpcrtests))
    settotalrdttests(numberWithCommas(data.totalrdttests))
    setHealthworker(numberWithCommas(data.healthworkerTests))
    setTraveler(numberWithCommas(data.travelers))

    settotalpositivetests(numberWithCommas(data.totalpositivetests))
    settotalpositiveSymptomatic(numberWithCommas(data.totalpositiveSymptomatic))
    settotalpositivenoSymptom(numberWithCommas(data.totalpositiveNoSymptom))
    settotalpositiveunknownSymptom(numberWithCommas(data.totalpositiveunknownSymptom))

    settotalNewpositivetests(numberWithCommas(data.newpositivetests))
    settotalNewpositiveSymptomatic(numberWithCommas(data.newpositivetestsSymptomatic))
    settotalNewpositivenoSymptom(numberWithCommas(data.newpositivetestsnoSymptom))
    settotalNewpositiveunknownSymptom(numberWithCommas(data.newpositivetestsunknownSymptom))

    setTotalVaccinated(numberWithCommas(data.totalVaccinated))
    setVaccinated1Dose(numberWithCommas(data.vaccinated1Dose))
    setVaccinated2Doses(numberWithCommas(data.vaccinated2Doses))
    setVaccinatedUnKnown(numberWithCommas(data.vaccinatedUnKnown))

    setLoaderCircle3(null)
  }

  /**************Reset filter fields ************************/
  const handleClear = () => {
    setsingleSelectReg("")
    setsingleSelectDis("")
    setsingleSelectHF("")
    setFromDate(null)
    setToDate(null)
  }

  const hideAlert = () => {
    setAlert(null);
  };
 
  const handleTotalsDownload = () => {
    let workbook = {}
    let worksheet = []
    let sheetname = ''
    //let rowdata = []
    const wb = XLSX.utils.book_new();
    console.log('Download data in excel sheet')
    // console.log(totalTestData)

  JSON.parse(totalTestData).forEach(item => {
  //  JSON.parse(JSON.stringify(totalTestData)).forEach(item => {
      let data2 = ''
      if (sheetname === '') {

        //1.set sheet name
        //2.set sheet data array item
        sheetname = item.date
        worksheet.push(['District', 'PCR Tests', 'RDT Tests', 'Total Tests']);
        data2 = [item.dist1, item.pcrTests, item.rdttests, item.totalTests]
        worksheet.push(data2);
      } else if (sheetname === item.date) {

        data2 = [item.dist1, item.pcrTests, item.rdttests, item.totalTests]
        worksheet.push(data2);
      } else if (sheetname !== item.date) {
        //let mydata= {$this->sheetname:''}
        // console.log('My Data'+JSON.stringify(mydata))
        let ws = XLSX.utils.aoa_to_sheet(worksheet);
        XLSX.utils.book_append_sheet(wb, ws, `${sheetname}`);

        workbook[sheetname] = worksheet
        sheetname = item.date
        worksheet = []
        sheetname = ''
        sheetname = item.date
        worksheet.push(['District', 'PCR Tests', 'RDT Tests', 'Total Tests']);
        data2 = [item.dist1, item.pcrTests, item.rdttests, item.totalTests]
        worksheet.push(data2);
      }
    })
    XLSX.writeFile(wb, "Covid19ByMonth-" + moment(new Date()).format('Do-MMM-YYYY') + ".xlsx")
  }

  const handlePositiveTotalsDownload = () => {
    // console.log("totAL POSITIVE TESTS DATA".JSON.stringify(totalPositiveTestData))
    let workbook = {}
    let worksheet = []
    let sheetname = ''
    let rowdata = []
    const wb = XLSX.utils.book_new();
    // console.log('Download positive test data in excel sheet' + JSON.stringify(totalPositiveTestData))

    JSON.parse(JSON.stringify(totalPositiveTestData)).forEach(item => {
      let data2 = ''
      if (sheetname === '') {
        //1.set sheet name
        //2.set sheet data array item
        sheetname = item.date
        worksheet.push(['District', 'PCR Positive Tests', 'RDT Positive Tests', 'Total Positive Tests']);
        data2 = [item.dist1, item.pcrTests, item.rdttests, item.totalTests]
        worksheet.push(data2);
      } else if (sheetname === item.date) {
        // data2 = {
        //   District: item.dist1,
        //   'PCR Tests': item.pcrTests,
        //   'RDT Tests': item.rdttests
        // }
        data2 = [item.dist1, item.pcrTests, item.rdttests, item.totalTests]
        worksheet.push(data2);
      } else if (sheetname !== item.date) {
        //let mydata= {$this->sheetname:''}
        // console.log('My Data'+JSON.stringify(mydata))
        let ws = XLSX.utils.aoa_to_sheet(worksheet);
        XLSX.utils.book_append_sheet(wb, ws, `${sheetname}`);

        workbook[sheetname] = worksheet
        sheetname = item.date
        worksheet = []
        sheetname = ''
        sheetname = item.date
        worksheet.push(['District', 'PCR Positive Tests', 'RDT Positive Tests', 'Total Positive Tests']);
        data2 = [item.dist1, item.pcrTests, item.rdttests, item.totalTests]
        worksheet.push(data2);
      }
    })
    XLSX.writeFile(wb, "Covid19PositiveTestsByMonth-" + moment(new Date()).format('Do-MMM-YYYY') + ".xlsx")
  }
  return (
    <>
      <div className="content" >
        {alert}
        <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth
          maxWidth={false} >
          <DialogContent style={{ 'padding': '3px 10px', 'overflow': 'hidden' }}>
            <Row>
              <Col md="12">
                <Card>
                  <CardBody style={{ 'padding': '' }} >
                    <Button className="btn-round btn-icon" style={{ 'background': '#d3d7e9', 'float': 'right', 'height': '1.375rem', 'min-width': '1.375rem', 'width': '1.375rem' }} onClick={() => setOpen(false)}>
                      <i className="tim-icons icon-simple-remove" />
                    </Button>
                    <Nav className="nav-pills-info" pills>
                      <NavItem>
                        <NavLink
                          data-toggle="tab"
                          href="#pablo"
                          className={horizontalTabs === "totalTests" ? "active" : ""}
                          onClick={(e) =>
                            changeActiveTab(e, "horizontalTabs", "totalTests")
                          }
                        >
                          Total Tests
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          data-toggle="tab"
                          href="#pablo"
                          className={horizontalTabs === "testByCategory" ? "active" : ""}
                          onClick={(e) =>
                            changeActiveTab(e, "horizontalTabs", "testByCategory")
                          }
                        >
                          Tests by Category(Who)
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent className="tab-space" activeTab={horizontalTabs} style={{ 'padding': '10px 0 10px 0px' }}>
                      <TabPane tabId="totalTests">
                        {loaderCircle5}
                        <div className="chart-area"
                          style={{ 'height': '360px' }}
                        >
                          <FormGroup check className="form-check-radio float-left" onChange={handleViewChange}>
                            <Label check>
                              <Input
                                defaultChecked={true}
                                defaultValue="totalgrouped"
                                id="totalgrouped"
                                name="viewByTotal"
                                type="radio"
                              />
                              <span className="form-check-sign" />
                              Grouped&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Label>
                          </FormGroup>
                          <FormGroup check className="form-check-radio float-left" onChange={handleViewChange} >
                            <Label check>
                              <Input
                                defaultValue="totalstacked"
                                id="totalstacked"
                                name="viewByTotal"
                                type="radio"
                              />
                              <span className="form-check-sign" />
                              Stacked
                            </Label>
                          </FormGroup>

                          <ButtonGroup
                            className="btn-group-toggle float-right"
                            data-toggle="buttons"
                          >
                            <Button color="success" size="sm" onClick={handleTotalsDownload}>
                              <Download className={classes.icons} />  Download
                            </Button>
                          </ButtonGroup>

                          <Bar
                            height={410}
                            data={TotalTestsBarData}
                            options={totalTestBarOptions.options}
                          />
                        </div>
                      </TabPane>
                      <TabPane tabId="testByCategory">
                        {loaderCircle5}
                        <div className="chart-area"
                          style={{ 'height': '360px' }}
                        >
                          <FormGroup check className="form-check-radio float-left" onChange={handleViewChange}>
                            <Label check>
                              <Input
                                defaultChecked={true}
                                defaultValue="totalcatgrouped"
                                id="totalcatgrouped"
                                name="viewBy"
                                type="radio"
                              />
                              <span className="form-check-sign" />
                              Grouped&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Label>
                          </FormGroup>
                          <FormGroup check className="form-check-radio float-left" onChange={handleViewChange} >
                            <Label check>
                              <Input
                                defaultValue="totalcatstacked"
                                id="totalcatstacked"
                                name="viewBy"
                                type="radio"
                              />
                              <span className="form-check-sign" />
                              Stacked
                            </Label>
                          </FormGroup>
                          <ButtonGroup
                            className="btn-group-toggle float-right"
                            data-toggle="buttons"
                          >
                            <Button color="success" size="sm" disabled onClick={handleTotalsDownload}>
                              <Download className={classes.icons} />  Download
                            </Button>
                          </ButtonGroup>
                          <Bar
                            height={410}
                            data={testCategoryBarData}
                            options={totalCatTestBarOptions.options}
                          />
                        </div>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col></Row>
          </DialogContent>
        </Dialog>
        <Dialog open={openPositive} aria-labelledby="form-dialog-title" fullWidth
          maxWidth={false} >
          <DialogContent style={{ 'padding': '3px 10px', 'overflow': 'hidden' }}>
            <Row>
              <Col md="12">
                <Card>
                  <CardBody style={{ 'padding': '' }} >
                    <Button className="btn-round btn-icon" style={{ 'background': '#d3d7e9', 'float': 'right', 'height': '1.375rem', 'min-width': '1.375rem', 'width': '1.375rem' }} onClick={() => setOpenPositive(false)}>
                      <i className="tim-icons icon-simple-remove" />
                    </Button>
                    {loaderCircle7}
                    <div className="chart-area"
                      style={{ 'height': '360px' }}
                    >
                      <FormGroup check className="form-check-radio float-left" onChange={handleViewChange}>
                        <Label check>
                          <Input
                            defaultChecked={true}
                            defaultValue="totalpositivegrouped"
                            id="totalpositivegrouped"
                            name="viewBy"
                            type="radio"
                          />
                          <span className="form-check-sign" />
                          Grouped&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Label>
                      </FormGroup>
                      <FormGroup check className="form-check-radio float-left" onChange={handleViewChange} >
                        <Label check>
                          <Input
                            defaultValue="totalpositivestacked"
                            id="totalpositivestacked"
                            name="viewBy"
                            type="radio"
                          />
                          <span className="form-check-sign" />
                          Stacked
                        </Label>
                      </FormGroup>

                      <ButtonGroup
                        className="btn-group-toggle float-right"
                        data-toggle="buttons"
                      >
                        <Button color="success" size="sm" onClick={handlePositiveTotalsDownload}>
                          <Download className={classes.icons} />  Download
                        </Button>
                      </ButtonGroup>

                      <Bar
                        height={410}
                        data={TotalPositiveTestsBarData}
                        options={totalPositiveTestBarOptions.options}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col></Row>
          </DialogContent>
        </Dialog>
        <Row>
          <Col lg="1" md="6" sm="3" style={{ 'padding-right': '0px', 'padding-left': '5px', 'padding-top': '0px', 'marginTop': '13px', 'textAlign': 'center' }}>
            <InputLabel styel={{paddingBottom:'15px'}}>From:</InputLabel>
            <InputLabel className={classes.label, "d-inline"}>Filter By:</InputLabel>
          </Col>
          <Col lg="1" md="6" sm="3" style={{ 'padding-right': '0px', 'padding-left': '5px', 'padding-top': '0px', 'marginTop': '13px' }}>
          <InputLabel styel={{ paddingBottom: '13px' }}>19th Sept 2022</InputLabel>
            <FormControl fullWidth>
              <Datetime
                dateFormat="DD-MMM-YYYY"
                timeFormat={false}
                isValidDate={valid}
                onChange={(date) => setFromDate(date)}
                inputProps={{ placeholder: "From Date:", style: { 'padding-top': '0px', 'paddingBottom': '0px', 'height': 'calc(1.2rem + 2px)', 'padding': '0.5rem 0.4rem', 'border': '0px', 'border-bottom': '1px solid #cad1d7', 'color': '#222a42' } }}
              />
            </FormControl>
          </Col>
          <Col lg="1" md="6" sm="3" style={{ 'padding-right': '0px', 'padding-left': '5px', 'padding-top': '0px', 'marginTop': '13px' }}>
          <InputLabel styel={{paddingBottom:'13px'}}> To date</InputLabel>
            <FormControl fullWidth >
              <Datetime
                dateFormat="DD-MMM-YYYY"
                timeFormat={false}
                isValidDate={valid}
                onChange={(date) => setToDate(date)}
                inputProps={{ placeholder: "To Date:", style: { 'padding-top': '0px', 'paddingBottom': '0px', 'height': 'calc(1.2rem + 2px)', 'padding': '0.5rem 0.4rem', 'border': '0px', 'border-bottom': '1px solid #cad1d7' } }}
              />
            </FormControl>
          </Col>
          <Col lg="2" md="6" sm="3" >
            <FormControl
              fullWidth
              className={classes.selectFormControl}
            >
              <InputLabel
                htmlFor="region"
                className={classes.selectLabel}
              >
                Region
              </InputLabel>

              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={singleSelectReg}
                onChange={handleRegionChange}
                inputProps={{
                  name: "region",
                  id: "region"
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  Choose Region
                </MenuItem>
                {regions.map((item) =>
                  <MenuItem classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                    value={item.value}>
                    {item.display}
                  </MenuItem>)}
              </Select>
            </FormControl>
          </Col>
          <Col lg="2" md="6" sm="3">
            <FormControl
              fullWidth
              className={classes.selectFormControl}
            >
              <InputLabel
                htmlFor="district"
                className={classes.selectLabel}
              >
                District
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={singleSelectDis}
                onChange={handleDistrictChange}
                inputProps={{
                  name: "district",
                  id: "district"
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  Choose District
                </MenuItem>
                {districts.map((item) =>
                  <MenuItem classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                    value={item.value}>
                    {item.display}
                  </MenuItem>)}
              </Select>
            </FormControl>

          </Col>
          <Col lg="2" md="6" sm="3">
            <FormControl
              fullWidth
              className={classes.selectFormControl}
            >
              <InputLabel
                htmlFor="facility"
                className={classes.selectLabel}
              >
                Health Facility
              </InputLabel>
              <Select

                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={singleSelectHF}
                onChange={handleFacilityChange}
                inputProps={{
                  name: "facility",
                  id: "facility"
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  Choose Health Facility
                </MenuItem>
                {facilities.map((item) =>
                  <MenuItem classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                    value={item.value}>
                    {item.display}
                  </MenuItem>)}
              </Select>
            </FormControl>

          </Col>
          <Col lg="1" md="6" sm="3">
            <FormControl
              fullWidth
              className={classes.selectFormControl}
            >
              <InputLabel
                htmlFor="ageGrp"
                className={classes.selectLabel}
              >
                Age Grp
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={singleSelectAge}
                onChange={handleAgeGrpChange}
                inputProps={{
                  name: "ageGrp",
                  id: "ageGrp"
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  Choose Age grp
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value=""
                >
                  None
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value="0-13"
                >
                  &#60;= 13 yrs
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value="14-18"
                >
                  14 - 18 yrs
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value="19-25"
                >
                  19 - 25 yrs
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value="26-150"
                >
                  &#62;25 yrs
                </MenuItem>
              </Select>
            </FormControl>
          </Col>
          <Col lg="1" md="6" sm="3">
            <FormControl
              fullWidth
              className={classes.selectFormControl}
            >
              <InputLabel
                htmlFor="sex"
                className={classes.selectLabel}
              >
                Sex
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={singleSelectSex}
                onChange={handleSexValChange}
                inputProps={{
                  name: "sex",
                  id: "sex"
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  Choose Sex
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value="Male"
                >
                  Male
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value="Female"
                >
                  Female
                </MenuItem>
              </Select>
            </FormControl>
          </Col>
          <Col lg="1" md="6" sm="3" style={{ 'paddingRight': '0px' }}>
            <Button2 justIcon round style={{ 'margin-top': '20px', 'background': '#f34' }} onClick={handleSearch}   disabled={true}>
              <Search className={classesBtn.icons} className={classesBtn.marginRight} />
            </Button2>
            <Button2 color="info " justIcon round style={{ 'margin-top': '20px' }} onClick={handleClear}   disabled={true}>
              <Refresh className={classesBtn.icons} />
            </Button2>
          </Col>
          {/* <Col lg="1" md="6" sm="3"></Col> */}
        </Row>
        <Row>
          <Col lg="4" md="6">
            <Card className="card-stats">
              <CardBody >
                <Row>
                  <Col >
                    <div className="numbers">
                    <p className="card-category" style={{ 'textAlign': 'center' }}>Total tests</p>
                      <CardTitle tag="h3"  style={{ 'textAlign': 'center' }}><a href="#" style={{ 'textAlign': 'center', 'color': '#1d253b' }}>{totaltests}</a></CardTitle>
                      <hr />
                      <Row><Col lg="4" md="6" style={{ 'textAlign': 'center', 'text-transform': 'uppercase', 'font-size': '0.75rem' }}>Health workers</Col>
                      <Col lg="8" md="6" style={{ 'textAlign': 'left' }}><h6>{healthworker}</h6></Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col >
                    <div className="numbers">
                      <p className="card-category" style={{ 'textAlign': 'center' }}>Cumulative Positive Tests</p>
                      <CardTitle tag="h3"  style={{ 'textAlign': 'center' }}><a href="#" style={{ 'textAlign': 'center', 'color': '#f34' }}>{totalpositivetests}</a></CardTitle>
                      <hr />
                      <Row><Col lg="4" md="6" style={{ 'textAlign': 'center', 'text-transform': 'uppercase', 'font-size': '0.75rem' }}>Health workers</Col>
                      <Col lg="8" md="6" style={{ 'textAlign': 'left' }}><h6>{totalpositivehw}</h6></Col>
                      </Row>
                      </div>
                  </Col>
                </Row> {loaderCircle3}
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col >
                    <div className="numbers">
                      <p className="card-category" style={{ 'textAlign': 'center' }}>Positive Tests(Last 24hrs)</p>
                      <CardTitle tag="h3"  style={{ 'textAlign': 'center' }}><a href="#" style={{ 'textAlign': 'center', 'color': '#f34' }}>{totalNewpositivetests}</a></CardTitle>
                      <hr />
                      <Row><Col lg="4" md="6" style={{ 'textAlign': 'center', 'text-transform': 'uppercase', 'font-size': '0.75rem' }}>Health workers</Col>
                      <Col lg="4" md="6" style={{ 'textAlign': 'left' }}><h6>{totalNewpositivehw}</h6></Col>
                      </Row>
                      </div>
                  </Col>
                </Row> {loaderCircle3}
              </CardBody>
            </Card>
          </Col>       
        </Row>

        <Row>
          <Col xs="12">
            <Card className="card-chart" style={{ 'margin-bottom': '20px', 'height': '500px' }}>
              <CardHeader style={{ 'padding-top': '12px' }}>
                <Row>
                  <Col className="text-left" sm="6">
                    <h3 className="card-category">Ebola Daily Tests </h3>
                    <FormGroup check className="form-check-radio float-left" onChange={handleViewChange}>
                      <Label check>
                        <Input
                          defaultChecked
                          defaultValue="grouped"
                          id="grouped"
                          name="viewBy"
                          type="radio"
                        />
                        <span className="form-check-sign" />
                        Grouped&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio float-left" onChange={handleViewChange} >
                      <Label check>
                        <Input
                          defaultValue="stacked"
                          id="stacked"
                          name="viewBy"
                          type="radio"
                        />
                        <span className="form-check-sign" />
                        Stacked
                      </Label>
                    </FormGroup></Col>
                  <Col sm="6" hidden={hideStudySummary}>
                    {/* <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button color="success" size="sm" onClick={handleElifDownload}>
                        <Download className={classes.icons} />  Download
                      </Button>
                    </ButtonGroup> */}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {loaderCircle}
                <div className="chart-area"
                  style={{ 'height': '420px' }}
                >
                  <Bar
                    height={410}
                    data={dailyPositiveTestsAgg}
                    options={positiveTestBarOptions.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="4">

            <Card className="card-tasks" style={{ 'height': '510px' }}>
              <CardHeader>
                <h5 className="card-category">Positive tests by age group</h5>

              </CardHeader>
              <CardBody>
                <div className="table-full-width " style={{ 'max-height': '380' }}>
                  {loaderCircle2}
                  <Bar
                    height={430}
                    data={positiveTestAgeBarData}
                    options={positiveTestAgeBarOptions.options}
                  /></div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="8" hidden={hideDistrictAgg}>
            <Card className="card-chart" style={{ 'height': '510px' }}>
              <CardHeader>
                <h5 className="card-category">Tests By District</h5>
                {/* <CardTitle tag="h3">
                  <i className="tim-icons icon-bell-55 text-primary" /> 763,215
                </CardTitle> */}
              </CardHeader>
              <CardBody style={{ 'padding-right': '2px' }}>
                <div className="chart-area table-full-width table-responsive"
                  // style={{ 'minHeight': '460px', 'overflowY': 'hidden', 'overflowX': 'hidden' }}
                  style={{ 'minHeight': '460px', 'overflowY': 'scroll', 'overflowX': 'hidden' }}
                >
                  {loaderCircle4}
                  <MDBDataTable
                    striped
                    bordered
                    entries={6}
                    hover
                    table-bordered
                    small
                    data={tabledata}
                    entries={6}
                    entriesOptions={[6, 10, 20, 50, 100]}
                    style={{ 'border-top': '1px ' }}
                    fixed={false}
                    maxHeight={360}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="5" hidden={hideStudySummary}>
            <Card className="card-chart" style={{ 'height': '510px' }}>
              <CardHeader>
                <h5 className="card-category">Positive Tests vaccination info</h5>
                {/* <CardTitle tag="h3">
                  <i className="tim-icons icon-bell-55 text-primary" /> 763,215
                </CardTitle> */}
              </CardHeader>
              <CardBody style={{ 'padding-right': '2px' }}>
                <div className="chart-area table-full-width table-responsive"
                  // style={{ 'minHeight': '460px', 'overflowY': 'hidden', 'overflowX': 'hidden' }}
                  style={{ 'minHeight': '460px', 'overflowY': 'scroll', 'overflowX': 'hidden' }}
                >
                  {loaderCircle4}
                  <MDBDataTable
                    striped
                    bordered
                    entries={10}
                    hover
                    table-bordered
                    small
                    data={vaccinationInfoData}
                    entriesOptions={[10, 20, 50, 100]}
                    style={{ 'border-top': '1px ' }}
                    fixed={false}
                    maxHeight={360}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" hidden={hideStudySummary}>
            <Card className="card-chart" style={{ 'height': '190px' }}>
              <CardHeader>
                <h5 className="card-category">Activity in 12 Study Health Facilities</h5>
              </CardHeader>
              <CardBody>
                <div className="chart-area" >
                  <GaugeChart id="gauge-chart1"
                    nrOfLevels={1}
                    arcsLength={[facilityActivity, 1 - facilityActivity]}
                    colors={['rgb(44,151,222)', '#E5E7E9']}
                    percent={facilityActivity}
                    arcPadding={0}
                    cornerRadius={0}
                    textColor={"#000"}
                    needleColor={"#fff"}
                    needleBaseColor={"#fff"}
                    animate={false}
                  />

                </div>
              </CardBody>
            </Card>
            <Card className="card-chart" style={{ 'height': '190px' }}>
              <CardHeader>
                <h5 className="card-category">User Activity in 12 Study Health Facilities</h5>
              </CardHeader>
              <CardBody>
                <div className="chart-area" >
                  <GaugeChart id="gauge-chart5"
                    nrOfLevels={1}
                    arcsLength={[userActivity, 1 - userActivity]}
                    colors={['#52BE80', '#E5E7E9']}
                    percent={userActivity}
                    arcPadding={0}
                    cornerRadius={0}
                    textColor={"#000"}
                    needleColor={"#fff"}
                    needleBaseColor={"#fff"}
                    animate={false}
                  />

                </div>
              </CardBody>
            </Card>

          </Col>

        </Row>
      </div>
    </>
  );
};

export default Ebola;
