/*!

=========================================================
* COVID-19 Dashboard- v1.2.0
=========================================================

* Product Page: http://cphl.go.ug/covid19dashboard
* Copyright 2020 Namutebi Laiton http://cphl.go.ug

* Coded by Namutebi Laiton

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

const Footer = (props) => {
  return (
    <footer className={"footer" + (props.default ? " footer-default" : "")}>
      <Container fluid={props.fluid ? true : false}>
        <div className="copyright">
          © {new Date().getFullYear()} {" "}
          
          <a href="http://216.104.204.156:3000/" target="_blank" style={{'color':'#B62506'}}>
            National Health Laboratory and Diagonistic Services(NHLDS)-CPHL Uganda
          </a>
        </div>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
