/*!

=========================================================
* COVID-19 Dashboard- v1.2.0
=========================================================

* Product Page: http://cphl.go.ug/covid19dashboard
* Copyright 2020 Namutebi Laiton http://cphl.go.ug

* Coded by Namutebi Laiton

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import moment from 'moment';
import DataHandlerService from "services/DataHandler.js"
import Datetime from "react-datetime";




import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  FormGroup,
  Label,
  Form,
  Row,
  Col,
  Button, Input,
} from "reactstrap";

// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

import ReactTable from "components/ReactTable/ReactTableCov.js";
// core components
import SortingTable from "components/SortingTable/SortingTable.js";

import InputLabel from "@material-ui/core/InputLabel";

//*********Dialog Box */

import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Search from "@material-ui/icons/Search";
import DialogTitle from '@material-ui/core/DialogTitle'
import Refresh from "@material-ui/icons/Refresh"
import FormControl from "@material-ui/core/FormControl"
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert"

import Button2 from "assets/jss/Button.js";

import styles from "assets/jss/extendedFormsStyle.js";
import stylesBtns from "assets/jss/buttonsStyle.js";

const useStyles = makeStyles(styles);
const useStylesBtns = makeStyles(stylesBtns);



const FlaggedFacilities = () => {

  const classes = useStyles();
  //  const classesBtn = useStylesBtns();
  const yesterday = moment().subtract(1, 'day');
  const valid = function (current) {
    return current.isBefore(yesterday);
  }
  const [tabledata, setTabledatadata] = React.useState([]);
  const [tabledata2, setTabledatadata2] = React.useState([]);
  const [indicatorText, setindicatorText] = React.useState("");
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);

  const [indicatorstats, setInsdicatorstats] = React.useState([]);
  const [refreshTable, setrefreshTable] = useState(0)
  const classesBtn = useStylesBtns();
  const randomNum = Math.floor(Math.random() * Math.floor(1000000))

  const warningWithConfirmMessage = (title, msg) => {
    setAlert(

      <ReactBSAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      >{msg}</ReactBSAlert>

    );
  };
  useEffect(() => {
    getFacilityIndicatorstats()
  }, [])
  useEffect(() => {
    getFlaggedFacilities()
  }, [refreshTable])
  const getFlaggedFacilities = () => {

    DataHandlerService.getFacilityInvalidInfo()
      .then(response => {
        //  console.log("data: "+JSON.stringify(response.data))
        displayFlaggedFacilities(response.data)
      })
      .catch(error => handleError(error))
  }
  const displayFlaggedFacilities = (data) => {
    let counter = 1
    let flaggedFacilities = []
    data.forEach(item => {
      // let itemDetail = {id:`${counter}`,region: "",  district:"", facility:`${item.facilityid.facility}`, status:`${item.status}`,dateflagged:`${item.dateflagged}`,  reason:`${item.indicatorid.indicator}`,TAT: (item.TAT > 0) ? `${item.TAT}` : "",  actions: (fillButtons(item.id, "", "", item.facilityid.facility, item.dateflagged, item.status, item.indicatorid.indicator, item.TAT, item.invalidcount, item.overallcount, item.comment,item.dateclosed)),
      // let itemDetail = ["", "", `${item.facilityid.facility}`, `${moment(item.item.dateflagged).format('YYYY-MM-DD')}`, `${item.status}`, `${item.indicatorid.indicator}`, (item.TAT > 0) ? `${item.TAT}` : "", fillButtons(item.id, "", "", item.facilityid.facility, moment(item.item.dateflagged).format('YYYY-MM-DD'), item.status, item.indicatorid.indicator, item.TAT, item.invalidcount, item.overallcount, item.comment, (item.dateclosed !== null)?moment(item.dateclosed).format('YYYY-MM-DD'):"")]
      //let itemDetail = ["", "", `${item.flagged.facilityid.facility}`, `${item.flagged.status}`, `${moment(item.flagged.dateflagged).format('DD-MMM-YYYY')}`, `${item.flagged.indicatorid.indicator}`, (item.flagged.TAT > 0) ? `${item.flagged.TAT}` : "", fillButtons(item.id, "", "", item.facilityid.facility, moment(item.dateflagged).format('DD-MMM-YYYY'), item.status, item.indicatorid.indicator, item.TAT, item.invalidcount, item.overallcount, item.comment, item.dateclosed)]

      let itemDetail = [`${item.region}`, `${item.district}`, `${item.facility}`, `${item.status}`, `${moment(item.dateflagged).format('DD-MMM-YYYY')}`, `${item.indicator}`, (item.TAT > 0) ? `${item.TAT}` : "", fillButtons(item.id, item.region, item.district, item.facility, moment(item.dateflagged).format('DD-MMM-YYYY'), item.status, item.indicator, item.TAT, item.invalidcount, item.overallcount, item.comment, item.dateclosed)]
      flaggedFacilities.push(itemDetail);
      counter = counter + 1
      // console.log(`items for table: ${itemDetail}`)
    })

    setTabledatadata(flaggedFacilities.map((prop, key) => {
      return {
        id: key,
        // region: prop[0],
        // district: prop[1],
        facility: prop[2],
        status: prop[3],
        dateflagged: prop[4],
        reason: prop[5],
        // TAT: prop[6],
        actions: prop[7],
      };
    }))
  }

  const handleClear = () => {
    // setsingleSelectReg("")
    // setsingleSelectDis("")
    // setsingleSelectHF("")
    setFromDate(null)
    setToDate(null)
  }

  const fillButtons = (id, region, district, facility, dateFlagged, status, indicator, tat, invalidcount, totalcount, comment, dateclosed) => {
    return (<div className="actions-right">
      <Button
        onClick={() => editviewFlaggingDetails(id, region, district, facility, dateFlagged, status, indicator, tat, invalidcount, totalcount, comment, dateclosed)}
        color="warning"
        size="sm"
        className={classNames("btn-icon btn-link like", {
          "btn-neutral": id < 5,
        })}
      >
        <i className="tim-icons icon-pencil" />
      </Button>{" "}
    </div>)
  }

  const handleSearch = () => {
    if (fromDate === null || toDate === null) {
      warningWithConfirmMessage("Missing Dates", "Enter the From Date and To Date!");
    }
  }

  const getFacilityIndicatorstats = () => {
    DataHandlerService.getFacilityIndicatorstats()
      .then(response => {
        console.log("Indicator : " + JSON.stringify(response.data))
        //  displayIndicatorstats(response.data)
        let indicatorStatsData = []
        let counter = 1
        response.data.forEach(item => {
          //  console.log("Item to render:" + JSON.stringify(item))
          if (counter < 13) {
            indicatorStatsData.push(displayIndicatorstats(item.indicator, item.invalidTestsNo, item.flaggedFacilityUserNo, item.facilityUsers))
          }
          counter = counter + 1
        })

        setInsdicatorstats(indicatorStatsData)
      })
      .catch(error => handleError(error))
  }
  const showInvalidDetails = (indicatortitle, facilities) => {
    let facilitiesTableData = []
    facilities.forEach(item => {
      let itemDetail = [`${item.interviewerName}`, `${item.invalidtests}`, `${item.totaltests}`, ""]

      facilitiesTableData.push(itemDetail)
    })

    setTabledatadata2(facilitiesTableData.map((prop, key) => {
      return {
        data: [
          { className: "text-left", text: prop[0] },
          { className: "text-center", text: prop[1] },
          { className: "text-center", text: prop[2] }, {}
        ],
      };
    }))
    setindicatorText(indicatortitle)
    setOpen2(true)

  }
  const displayIndicatorstats = (indicatortext, invalidscount, facilitycount, facilities) => {

    return (
      <Col lg="2" md="6" style={{ 'margin-bottom': '80px', 'height': '100px', 'padding-left': '5px' }}>
        <Card className="card-stats">
          <CardBody>
            <Row>
              <Col xs="12" style={{ 'padding-right': '10px' }}>
                <div className="numbers">
                  <p className="card-category">{indicatortext} </p>
                  <CardTitle tag="h3"><a href="#" onClick={() => showInvalidDetails(indicatortext, facilities)}>{invalidscount}</a></CardTitle>
                </div>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <hr />
            <div className="stats">
              No. of Health Facility user: <a href="#" onClick={() => showInvalidDetails(indicatortext, facilities)}>{facilitycount}</a>
            </div>
          </CardFooter>
        </Card>
      </Col>
    )

  }

  const handleError = (error) => {
    console.log(JSON.stringify(error));
  }

  const [alert, setAlert] = useState(null)
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [commentState, setCommentState] = React.useState("has-success");
  const [id, setId] = useState("")
  const [region, setRegion] = useState("")
  const [district, setDistrict] = useState("")
  const [facility, setFacility] = useState("")
  const [dateFlagged, setDateFlagged] = useState("")
  const [status, setStatus] = useState("")
  const [status2, setStatus2] = useState("")
  const [indicator, setIndicator] = useState("")
  const [tat, setTat] = useState("")
  const [invalidcount, setInvalidcount] = useState("")
  const [totalcount, setTotalcount] = useState("")
  const [dateclosed, setDateclosed] = useState("")
  const [comment, setComment] = useState("")
  const [commentOld, setCommentOld] = useState("")
  const hideAlert = () => { setAlert(null) }
  const handleClickOpen = () => {
    setOpen(true)

  }
  const handleClose = () => {
    setOpen(false)
    setOpen2(false)
    setTabledatadata2([])
    setId("")
    setRegion("")
    setDistrict("")
    setFacility("")
    setDateFlagged("")
    setStatus("")
    setStatus2("")
    setIndicator("")
    setTat("")
    setInvalidcount("")
    setTotalcount("")
    setCommentOld("")
    setComment("")
    setDateclosed("")
  }

  const editviewFlaggingDetails = (dbid, dbregion, dbdistrict, dbfacility, dbdateFlagged, dbstatus, dbindicator, dbtat, dbinvalidcount, dbtotalcount, dbcomment, dbdateclosed) => {
    let closedDate = (dbdateclosed !== null) ? moment(dbdateclosed).format('DD-MMM-YYYY') : null

    setOpen(true)
    setId(dbid)
    setRegion(dbregion)
    setDistrict(dbdistrict)
    setFacility(dbfacility)
    setDateFlagged(dbdateFlagged)
    setStatus(dbstatus)
    setStatus2(dbstatus)
    setIndicator(dbindicator)
    setTat(dbtat)
    setInvalidcount(dbinvalidcount)
    setTotalcount(dbtotalcount)
    setCommentOld(dbcomment)
    setDateclosed(dbdateclosed)
  }

  const handleSave = () => {
    if (comment === "") {
      setCommentState("has-danger");
    } else {
      let data = {
        comment: comment,
        status: status,
        id: id
      }
      DataHandlerService.updateFlaggedFacility(data)
        .then(response => {
          setrefreshTable(randomNum)
          successAlert()
          handleClose()
        })
        .catch(error => handleError(error))
    }
  }

  const change = (event, stateName, type) => {
    switch (type) {
      case "comment":
        setComment(event.target.value)
        // if ((event.target.value) !== "") {
        //   setCommentState("has-success");          
        // } else {
        //   setCommentState("has-danger");
        // }      
        break;
      default:
        break;
    }
  }
  const handleStatusChange = event => {
    // console.log("Selected" + event.target.value)
    setStatus(event.target.value)
  };
  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Updated"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      >
        Successfully Updated
      </ReactBSAlert>
    );
  };

  return (
    <>
      <div className="content">
        {alert}
        {/* the dialog of new/ edit of category */}
        <Dialog open={open2} onClose={handleClose} aria-labelledby="form-dialog-title" >
          <DialogTitle id="form-dialog-title">View/Edit</DialogTitle>
          <DialogContent style={{ 'width': '600px' }}>
            <h6>{indicatorText}</h6>
            <SortingTable
              thead={[
                { className: "text-left", text: "Interviewer Name" },
                { className: "text-left", text: "Invalid" },
                { className: "text-left", text: "Total" },
              ]}
              tbody={tabledata2}
            /> </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="warning">
              Close
            </Button>

          </DialogActions>
        </Dialog>

        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
          <DialogTitle id="form-dialog-title">View/Edit</DialogTitle>
          <DialogContent style={{ 'width': '600px' }}>
            <Form className="form-horizontal">
              <Row><Label md="2">Region</Label>
                <Col md="4">
                  <FormGroup>
                    <Input value={region} disabled={true} type="text" style={{ 'border': '1px solid #cad1d7', 'color': '#222a42' }} />
                  </FormGroup>
                </Col>
                <Label md="2">District</Label>
                <Col md="4">
                  <FormGroup>
                    <Input value={district} disabled={true} type="text" style={{ 'border': '1px solid #cad1d7', 'color': '#222a42' }} />
                  </FormGroup>
                </Col>
              </Row>
              <Row><Label md="2" style={{ 'paddingTop': '0px' }}>Invalid Count</Label>
                <Col md="4">
                  <FormGroup>
                    <Input value={invalidcount} disabled={true} type="text" style={{ 'border': '1px solid #cad1d7', 'color': '#222a42' }} />
                  </FormGroup>
                </Col>
                <Label md="2" style={{ 'paddingTop': '0px' }}>Total count</Label>
                <Col md="4">
                  <FormGroup>
                    <Input value={totalcount} disabled={true} type="text" style={{ 'border': '1px solid #cad1d7', 'color': '#222a42' }} />
                  </FormGroup>
                </Col>

              </Row>
              <Row><Label md="3">Health Facility</Label>
                <Col md="9">
                  <FormGroup>
                    <Input value={facility} disabled={true} type="text" style={{ 'border': '1px solid #cad1d7', 'color': '#222a42' }} />
                  </FormGroup>
                </Col>
              </Row>
              <Row><Label md="3">Indicator</Label>
                <Col md="9">
                  <FormGroup>
                    <Input value={indicator} disabled={true} type="textarea" style={{ 'border': '1px solid #cad1d7', 'color': '#222a42' }} />
                  </FormGroup>
                </Col>
              </Row>
              <Row><Label md="3">Date Flagged</Label>
                <Col md="3">
                  <FormGroup>
                    <Input value={dateFlagged} disabled={true} type="text" style={{ 'border': '1px solid #cad1d7', 'color': '#222a42' }} />
                  </FormGroup>
                </Col>
                <Label md="3">Date Closed</Label>
                <Col md="3">
                  <FormGroup>
                    <Input value={dateclosed} disabled={true} type="text" style={{ 'border': '1px solid #cad1d7', 'color': '#222a42' }} />
                  </FormGroup>
                </Col>
              </Row>
              <Row><Label md="3">Comment</Label>
                <Col md="9">
                  <FormGroup >
                    {commentOld}
                  </FormGroup>
                </Col>
              </Row>
              <Row><Label md="3">Status</Label>
                <Col md="9">
                  <FormGroup check inline className="form-check-radio" onChange={handleStatusChange}>
                    <Label check>
                      <Input
                        defaultChecked={(status2 === "Pending") ? true : false}
                        defaultValue="Pending"
                        id="Pending"
                        name="status"
                        type="radio" disabled
                      />
                      <span className="form-check-sign" />
                      Pending
                    </Label>
                  </FormGroup>
                  <FormGroup check inline className="form-check-radio" onChange={handleStatusChange}>
                    <Label check>
                      <Input
                        defaultChecked={(status === "On-going") ? true : false}
                        defaultValue="On-going"
                        id="On-going"
                        name="status"
                        type="radio"
                        disabled
                      />
                      <span className="form-check-sign" />
                      On-going
                    </Label>
                  </FormGroup>
                  <FormGroup check inline className="form-check-radio " onChange={handleStatusChange}>
                    <Label check>
                      <Input
                        defaultChecked={(status === "Complete") ? true : false}
                        defaultValue="Complete"
                        id="Complete"
                        name="status"
                        type="radio"
                        disabled
                      />
                      <span className="form-check-sign" />
                      Complete
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="warning">
              Cancel
            </Button>

          </DialogActions>
        </Dialog>
        <Col md={8} className="ml-auto mr-auto">
          <h3 className="text-center" style={{ 'margin-bottom': '15px' }}> Performance Indicators  </h3>
        </Col>
        <Row>
          <Col lg="1" md="6" sm="3" style={{ 'padding-right': '0px', 'padding-left': '5px', 'padding-top': '24px', 'marginTop': '13px' }}>


          </Col>
          <Col lg="2" md="6" sm="3" >

          </Col>
          <Col lg="1" md="6" sm="3" style={{ 'padding-right': '0px', 'padding-left': '5px', 'padding-top': '14px', 'marginTop': '5px', 'textAlign': 'center' }}>
            <InputLabel className={classes.label, "d-inline"}>Filter By:</InputLabel>
          </Col>
          <Col lg="2" md="6" sm="3">
            <FormControl fullWidth>
              <Datetime
                dateFormat="DD-MMM-YYYY"
                timeFormat={false}
                isValidDate={valid}
                onChange={(date) => setFromDate(date)}
                inputProps={{ placeholder: "From Date:", style: { 'border': '0px', 'border-bottom': '1px solid #cad1d7', 'color': '#222a42' } }}
              />
            </FormControl>
          </Col>
          <Col lg="2" md="6" sm="3">
            <FormControl fullWidth >
              <Datetime
                dateFormat="DD-MMM-YYYY"
                timeFormat={false}
                isValidDate={valid}
                onChange={(date) => setToDate(date)}
                inputProps={{ placeholder: "To Date:", style: { 'border': '0px', 'border-bottom': '1px solid #cad1d7' } }}
              />
            </FormControl>
          </Col>
          <Col lg="1" md="6" sm="3" style={{ 'paddingRight': '0px' }}>
            <Button2 justIcon round style={{ 'background': '#f34' }} onClick={handleSearch}>
              <Search className={classesBtn.icons} className={classesBtn.marginRight} />
            </Button2>
            <Button2 color="info " justIcon round onClick={handleClear}>
              <Refresh className={classesBtn.icons} />
            </Button2>
          </Col>
          <Col lg="3" md="6" sm="3"></Col>
        </Row>
        <Row>
          {indicatorstats}
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4" style={{ 'margin-bottom': '0.05rem' }}>Health facilities to be supervised</CardTitle>
              </CardHeader>
              <CardBody style={{ 'paddingTop': '5px' }}>
             
                <ReactTable
                  data={tabledata}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: "Health Facility",
                      accessor: "facility",
                    },
                    {
                      Header: "Date Flagged",
                      accessor: "dateflagged",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                    },
                    {
                      Header: "Reason",
                      accessor: "reason",
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  defaultPageSize={3}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped-highlight"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FlaggedFacilities;
