/*!

=========================================================
* COVID-19 Dashboard- v1.2.0
=========================================================

* Product Page: http://cphl.go.ug/covid19dashboard
* Copyright 2020 Namutebi Laiton http://cphl.go.ug

* Coded by Namutebi Laiton

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import moment from 'moment';
import DataHandlerService from "services/DataHandler.js"
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";

import ReactTable from "components/ReactTable/ReactTableCov.js";

const dataTable = [
  ["Airi Satou", "Accountant", "Tokyo", "33"],
  ["Angelica Ramos", "Chief Executive Officer (CEO)", "London", "47"],
  ["Ashton Cox", "Junior Technical Author", "San Francisco", "66"],
  ["Bradley Greer", "Software Engineer", "London", "41"],
  ["Brenden Wagner", "Software Engineer", "San Francisco", "28"],
  ["Brielle Williamson", "Integration Specialist", "New York", "61"],
  ["Caesar Vance", "Pre-Sales Support", "New York", "21"],
  ["Cedric Kelly", "Senior Javascript Developer", "Edinburgh", "22"],
  ["Charde Marshall", "Regional Director", "San Francisco", "36"],
  ["Colleen Hurst", "Javascript Developer", "San Francisco", "39"],
  ["Dai Rios", "Personnel Lead", "Edinburgh", "35"],
  ["Doris Wilder", "Sales Assistant", "Sidney", "23"],
  ["Fiona Green", "Chief Operating Officer (COO)", "San Francisco", "48"],
  ["Garrett Winters", "Accountant", "Tokyo", "63"],
  ["Gavin Cortez", "Team Leader", "San Francisco", "22"],
  ["Gavin Joyce", "Developer", "Edinburgh", "42"],
  ["Gloria Little", "Systems Administrator", "New York", "59"],
  ["Haley Kennedy", "Senior Marketing Designer", "London", "43"],
  ["Herrod Chandler", "Sales Assistant", "San Francisco", "59"],
  ["Hope Fuentes", "Secretary", "San Francisco", "41"],
  ["Howard Hatfield", "Office Manager", "San Francisco", "51"],
  ["Jackson Bradshaw", "Director", "New York", "65"],
  ["Jena Gaines", "Office Manager", "London", "30"],
  ["Jenette Caldwell", "Development Lead", "New York", "30"],
  ["Jennifer Chang", "Regional Director", "Singapore", "28"],
  ["Martena Mccray", "Post-Sales support", "Edinburgh", "46"],
  ["Michael Silva", "Marketing Designer", "London", "66"],
  ["Michelle House", "Integration Specialist", "Sidney", "37"],
  ["Olivia Liang", "Support Engineer", "Singapore", "64"],
  ["Paul Byrd", "Chief Financial Officer (CFO)", "New York", "64"],
  ["Prescott Bartlett", "Technical Author", "London", "27"],
  ["Quinn Flynn", "Support Lead", "Edinburgh", "22"],
  ["Rhona Davidson", "Integration Specialist", "Tokyo", "55"],
  ["Shou Itou", "Regional Marketing", "Tokyo", "20"],
  ["Sonya Frost", "Software Engineer", "Edinburgh", "23"],
  ["Suki Burks", "Developer", "London", "53"],
  ["Tatyana Fitzpatrick", "Regional Director", "London", "19"],
  ["Timothy Mooney", "Office Manager", "London", "37"],
  ["Unity Butler", "Marketing Designer", "San Francisco", "47"],
  ["Vivian Harrell", "Financial Controller", "San Francisco", "62"],
  ["Yuri Berry", "Chief Marketing Officer (CMO)", "New York", "40"],
  ["Tiger Nixon", "System Architect", "Edinburgh", "61"],
];


const Logistics = () => {
  const [tabledata, setTabledatadata] = React.useState([]);
const handleError = (error) => {
  console.log(JSON.stringify(error));
}
  const [data, setData] = React.useState(
    dataTable.map((prop, key) => {
      return {
        id: key,
        region: prop[0],
        district: prop[1],
        facility: prop[2],
        commodity: prop[3],
        opening: prop[0],
        recieved: prop[1],
        consumed: prop[2],
        adjusted: prop[3],
     adjusted: prop[3],
      };
    })
  );
  useEffect(() => {
    getFacilitiesStock()
  }, [])

  const getFacilitiesStock = () => {

    DataHandlerService.getFacilitiesStock()
      .then(response => {
        console.log("data: "+JSON.stringify(response.data))
        displayFacilitiesStock(response.data)
      })
      .catch(error => handleError(error))
  }

  const displayFacilitiesStock = (data) => {
    let counter = 1
    let FacilitiesStock = []
    data.forEach(item => {
      // let itemDetail = {id:`${counter}`,region: "",  district:"", facility:`${item.facilityid.facility}`, status:`${item.status}`,dateflagged:`${item.dateflagged}`,  reason:`${item.indicatorid.indicator}`,TAT: (item.TAT > 0) ? `${item.TAT}` : "",  actions: (fillButtons(item.id, "", "", item.facilityid.facility, item.dateflagged, item.status, item.indicatorid.indicator, item.TAT, item.invalidcount, item.overallcount, item.comment,item.dateclosed)),
      // let itemDetail = ["", "", `${item.facilityid.facility}`, `${moment(item.item.dateflagged).format('YYYY-MM-DD')}`, `${item.status}`, `${item.indicatorid.indicator}`, (item.TAT > 0) ? `${item.TAT}` : "", fillButtons(item.id, "", "", item.facilityid.facility, moment(item.item.dateflagged).format('YYYY-MM-DD'), item.status, item.indicatorid.indicator, item.TAT, item.invalidcount, item.overallcount, item.comment, (item.dateclosed !== null)?moment(item.dateclosed).format('YYYY-MM-DD'):"")]
      //let itemDetail = ["", "", `${item.flagged.facilityid.facility}`, `${item.flagged.status}`, `${moment(item.flagged.dateflagged).format('DD-MMM-YYYY')}`, `${item.flagged.indicatorid.indicator}`, (item.flagged.TAT > 0) ? `${item.flagged.TAT}` : "", fillButtons(item.id, "", "", item.facilityid.facility, moment(item.dateflagged).format('DD-MMM-YYYY'), item.status, item.indicatorid.indicator, item.TAT, item.invalidcount, item.overallcount, item.comment, item.dateclosed)]
      
      let itemDetail = [`${item.region}`, `${item.district}`, `${item.facility}`, `${item.commodity}`, `${item.openingbal}`,`${item.quantityrecieved}`,`${item.totalconsump}`,`${item.adjustment}`,`${item.closingbal}`,`${item.amc}`,`${moment(item.fromdate).format('DD-MMM-YYYY')}`, `${moment(item.todate).format('DD-MMM-YYYY')}`, `${moment(item.datesubmitted).format('DD-MMM-YYYY')}`]
      //, fillButtons(item.id, item.region, item.district, item.facility, moment(item.dateflagged).format('DD-MMM-YYYY'), item.status, item.indicator, item.TAT, item.invalidcount, item.overallcount, item.comment, item.dateclosed)]
      FacilitiesStock.push(itemDetail);
      counter = counter + 1
      // console.log(`items for table: ${itemDetail}`)
    })

    setTabledatadata(FacilitiesStock.map((prop, key) => {
      return {
        region: prop[0],
        district: prop[1],
        facility: prop[2],
        commodity: prop[3],
        opening: prop[4],
        recieved: prop[5],
        consumed: prop[6],
        adjusted: prop[7],
        closing: prop[8],
        amc: prop[9],
        fromdate: prop[10],
        todate: prop[11],
        submitted: "",
        //submitted: prop[12],
      };
    }))
  }
  return (
    <>
      <div className="content">
        <Col md={8} className="ml-auto mr-auto">
          <h2 className="text-center">Stock Details</h2>         
        </Col>
        <Row className="mt-5">
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Health Facility Stock Details</CardTitle>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={tabledata}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: "Region",
                      accessor: "region",
                    },
                    {
                      Header: "District",
                      accessor: "district",
                    },
                    {
                      Header: "Health Facility",
                      accessor: "facility",
                    },
                    {
                      Header: "Commodity",
                      accessor: "commodity",
                    },
                    {
                      Header: "Opening Bal",
                      accessor: "opening",
                    },
                    {
                      Header: "Qty Recieved",
                      accessor: "recieved",
                    },
                    {
                      Header: "Total Consumed",
                      accessor: "consumed",
                    },
                    {
                      Header: "Adjusted",
                      accessor: "adjusted",
                    },
                    {
                      Header: "Closing bal",
                      accessor: "closing",
                    },
                    {
                      Header: "AMC",
                      accessor: "amc",
                    },
                    {
                      Header: "From Date",
                      accessor: "fromdate",
                    },
                    {
                      Header: "To Date",
                      accessor: "todate",
                    },
                    {
                      Header: "Submit Date",
                      accessor: "submitted",
                    },
                 
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight "
                  style={{'text-align': 'center'}}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Logistics;
