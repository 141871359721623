/*!

=========================================================
* COVID-19 Dashboard- v1.2.0
=========================================================

* Product Page: http://cphl.go.ug/covid19dashboard
* Copyright 2020 Namutebi Laiton http://cphl.go.ug

* Coded by Namutebi Laiton

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import moment from 'moment';
import Datetime from "react-datetime";
import DataHandlerService from "services/DataHandler.js"
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  FormGroup,
  Label,
  Form,
  Row,
  Col,
  Button, Input, Badge,
} from "reactstrap";

// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import ReactTable from "components/ReactTable/ReactTableCov.js";
// core components
import SortingTable from "components/SortingTable/SortingTable.js";

//*********Dialog Box */
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Refresh from "@material-ui/icons/Refresh"
import Search from "@material-ui/icons/Search";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
import { MDBDataTable } from 'mdbreact';
import FormControl from "@material-ui/core/FormControl"
import styles from "assets/jss/extendedFormsStyle.js";
import stylesBtns from "assets/jss/buttonsStyle.js";
import InputLabel from "@material-ui/core/InputLabel";
import Button2 from "assets/jss/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import { CSVLink } from "react-csv";

const useStyles = makeStyles(styles);
const useStylesBtns = makeStyles(stylesBtns);
const FlaggedFacilities = () => {
  const yesterday = moment().subtract(1, 'day');
  const valid = function (current) {
    return current.isBefore(yesterday);
  }
  const classes = useStyles()
  const classesBtn = useStylesBtns()
  const [inSearchMode, setInSearchMode] = React.useState(false);
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [tabledata, setTabledatadata] = React.useState([]);
  const [tabledata2, setTabledatadata2] = React.useState([]);
  const [tabledata4, setTabledatadata4] = React.useState([]);
  const [indicatorText, setindicatorText] = React.useState("");
  const [facilityText, setfacilityText] = React.useState("");
  const [indicatorstats, setInsdicatorstats] = React.useState([]);
  const [refreshTable, setrefreshTable] = useState(0)
  const randomNum = Math.floor(Math.random() * Math.floor(1000000))
  const [tabledata3, setTabledata3] = React.useState({
    columns: [
      {
        label: 'id',
        field: 'id',
        sort: 'asc',
        width: 150
      }
      // ,
      // {
      //   label: 'Region     ',
      //   field: 'region',
      //   sort: 'asc',
      //   width: 400
      // },
      // {
      //   label: 'District     ',
      //   field: 'district',
      //   sort: 'asc',
      //   width: 400
      // }
      ,
      {
        label: 'Health Facility     ',
        field: 'facility',
        sort: 'asc',
        width: 400
      },
      {
        label: 'Date Flagged',
        field: 'flaggedon',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Indicator',
        field: 'indicator',
        sort: 'asc',
        width: 100
      }
      // ,
      // {
      //   label: 'TAT',
      //   field: 'tat',
      //   sort: 'asc',
      //   width: 100
      // }
      ,
      {
        label: 'Actions',
        field: 'actions',
        sort: 'asc',
        width: 100
      }
    ],
    rows: []
  });
  //All Flagged Facilities Data
  const [allActivePending, setAllActivePending] = React.useState([]);
  const [allActiveOngoing, setAllActiveOngoing] = React.useState([]);
  const [allActiveComplete, setAllActiveComplete] = React.useState([]);
  const [allInactive, setAllInactive] = React.useState([]);
  const [pending, setPending] = React.useState("0");
  const [ongoing, setOngoing] = React.useState("0");
  const [complete, setComplete] = React.useState("0");
  const [inactive, setInactive] = React.useState("0");

  const warningWithConfirmMessage = (title, msg) => {
    setAlert(

      <ReactBSAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      >{msg}</ReactBSAlert>

    );
  };
 

  const handleClear = () => {
    // setsingleSelectReg("")
    // setsingleSelectDis("")
    // setsingleSelectHF("")
    setFromDate(null)
    setToDate(null)
  }



  const checktokenexpiry = () => {
    DataHandlerService.executeTokenRefreshService()
      .then(response => {
        //  console.log("Token refreshing:-" + response.data.token)
        localStorage.setItem("token", response.data.token)
        getIndicatorstats()
        getFlaggedFacilities()
      }).catch(error => {
        getToken()

        //  console.log("Token refresh failed:-" + error)
      }
      )//If token refresh fails try relogin else server unreachable

  }

  const getToken = () => {
    DataHandlerService.executeJWTAutheticationService()
      .then(response => {
        // console.log(JSON.stringify(response.data))
        localStorage.setItem("token", response.data.token)
        localStorage.setItem("username", response.data.username)//currently using fixed account
        localStorage.setItem("reflab", response.data.reflab)//reflab
        localStorage.setItem("type", '999')//for default login
        localStorage.setItem("loggedin", false)
        getIndicatorstats()
        getFlaggedFacilities()
        //  setrefreshTable(randomNum)//Ensures table is refreshed on reload of items   

      })
      .catch(error => handleError(error))
  }
  useEffect(() => {
    const interval = setInterval(() => {
      checktokenexpiry()
    }, 3420000);//Refresh token after 57mins because it expires at 1 hour 
    return () => clearInterval(interval);

  }, [])
  // getIndicatorstats()  getFlaggedFacilities()
  useEffect(() => {
    getIndicatorstats()
  }, [])
   useEffect(() => {
    getAllFlaggedFacilities()
  }, [refreshTable])

  const getAllFlaggedFacilities = () => {
//let overallFlags = []
    DataHandlerService.getFlaggedFacilitiesCount()
      .then(response => {
        let resData = JSON.parse(JSON.stringify(response.data))
        setPending(resData.pendingCount);
        setOngoing(resData.ongoingCount);
        setComplete(resData.completeCount);
        setInactive(resData.InactiveCount);

      })
      .catch(error => handleError(error))

      DataHandlerService.getAllFlaggedFacilities()
      .then(response => {
        //  console.log("data: " + JSON.stringify(response.data))
       
        displayAllFlaggedFacilities(response.data)
      })
      .catch(error => handleError(error))
  }
  const displayAllFlaggedFacilities = (data) => {    
    let counter = 1
    let flaggedFacilities = []
    data.forEach(item => {
      let comments = []
      item.flaggedfacilitiesfollowupList.forEach(followup => {
        let commentDetail = [`${moment(followup.created_at).format('DD-MMM-YYYY')}`, `${followup.comment}`]
        comments.push(commentDetail)
      })
      let users = []
      item.flaggedfacilityusersList.forEach(user => {
        let userdetail = [`${user.familyName}` + ' ' + ` ${user.otherName}`, `${user.email}`, `${user.telephone}`]
        users.push(userdetail)
      })
   
      let itemDetail = [`${item.facilityid.facility}`, `${item.status}`, `${moment(item.dateflagged).format('DD-MMM-YYYY')}`, `${item.indicatorid.indicator}`, (item.TAT > 0) ? `${item.TAT}` : "", fillButtons(`${item.id}`, item.facilityid.facility, moment(item.dateflagged).format('DD-MMM-YYYY'), item.status, item.indicatorid.indicator, item.TAT, item.invalidcount, item.overallcount, comments, item.dateclosed, users)]
      flaggedFacilities.push(itemDetail);
      counter = counter + 1
      // console.log(`items for table: ${itemDetail}`)
    })

    setTabledata3(
      {
        columns: [
          {
            label: 'id',
            field: 'id',
            sort: 'asc',
            width: 150
          },
          // {
          //   label: 'Region     ',
          //   field: 'region',
          //   sort: 'asc',
          //   width: 400
          // },
          // {
          //   label: 'District     ',
          //   field: 'district',
          //   sort: 'asc',
          //   width: 400
          // },
          {
            label: 'Health Facility     ',
            field: 'facility',
            sort: 'asc',
            width: 400
          },
          {
            label: 'Date Flagged',
            field: 'flaggedon',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Status',
            field: 'status',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Indicator',
            field: 'indicator',
            sort: 'asc',
            width: 100
          }
          ,
          {
            label: 'TAT',
            field: 'tat',
            sort: 'asc',
            width: 100
          }
          ,
          {
            label: 'Actions',
            field: 'actions',
            sort: 'asc',
            width: 100
          }
        ],
        rows: flaggedFacilities.map((prop, key) => {
          return {//`${item.facilityid.facility}`, `${item.status}`, `${moment(item.dateflagged).format('DD-MMM-YYYY')}`, `${item.indicatorid.indicator}`, (item.TAT > 0) ? `${item.TAT}` : "", fillButtons(item.facilityid.facility, moment(item.dateflagged).format('DD-MMM-YYYY'), item.status, item.indicatorid.indicator, item.TAT, item.invalidcount, item.overallcount, comments, item.dateclosed,users)
            id: key,
            facility: prop[0],
            status: prop[1],
            flaggedon: prop[2],
            indicator: prop[3],
            TAT: prop[4],
            actions: prop[5],
          };
        })
      }
    )
    setTabledatadata(flaggedFacilities.map((prop, key) => {
      return {
        id: key,
        facility: prop[2],
        status: prop[3],
        dateflagged: prop[4],
        indicator: prop[5],
        TAT: prop[6],
        actions: prop[7],
      };
    }))
  }

  const getFlaggedFacilities = () => {

    DataHandlerService.getFlaggedFacilities()
      .then(response => {
        //  console.log("data: " + JSON.stringify(response.data))
        displayFlaggedFacilities(response.data)
      })
      .catch(error => handleError(error))
  }

  const getPendingFlaggedFacilities = () => {
    if(inSearchMode){
      DataHandlerService.getPendingFlaggedFacilitiesByDate()
      .then(response => {
        //  console.log("data: " + JSON.stringify(response.data))
       
        displayAllFlaggedFacilities(response.data)
      })
      .catch(error => handleError(error))
    }else{
    DataHandlerService.getPendingFlaggedFacilities()
    .then(response => {
        // console.log("data Pending: " + JSON.stringify(response.data))
     
      displayAllFlaggedFacilities(response.data)
    })
    .catch(error => handleError(error))
  }
  }

  const getOngoingFlaggedFacilities = () => {
    DataHandlerService.getOngoingFlaggedFacilities()
    .then(response => {
        // console.log("data on going: " + JSON.stringify(response.data))
     
      displayAllFlaggedFacilities(response.data)
    })
    .catch(error => handleError(error))
  }

  const getCompleteFlaggedFacilities = () => {
    DataHandlerService.getCompleteFlaggedFacilities()
    .then(response => {
        // console.log("data completed flags: " + JSON.stringify(response.data))
     
      displayAllFlaggedFacilities(response.data)
    })
    .catch(error => handleError(error))
  }

  const getInactiveFlaggedFacilities = () => {
    DataHandlerService.getInactiveFlaggedFacilities()
    .then(response => {
      //  console.log("data Inactive: " + JSON.stringify(response.data))
     
      displayAllFlaggedFacilities(response.data)
    })
    .catch(error => handleError(error))
  }

  const displayFlaggedFacilities = (data) => {
    let counter = 1
    let flaggedFacilities = []
    data.forEach(item => {
      let comments = []
      item.flaggedfacilitiesfollowupList.forEach(followup => {
        let commentDetail = [`${moment(followup.created_at).format('DD-MMM-YYYY')}`, `${followup.comment}`]
        comments.push(commentDetail)
      })
      let users = []
      item.flaggedfacilityusersList.forEach(user => {
        let userdetail = [`${user.familyName}` + ' ' + ` ${user.otherName}`, `${user.email}`, `${user.telephone}`]
        users.push(userdetail)
      })
      // let itemDetail = {id:`${counter}`,region: "",  district:"", facility:`${item.facilityid.facility}`, status:`${item.status}`,dateflagged:`${item.dateflagged}`,  reason:`${item.indicatorid.indicator}`,TAT: (item.TAT > 0) ? `${item.TAT}` : "",  actions: (fillButtons(item.id, "", "", item.facilityid.facility, item.dateflagged, item.status, item.indicatorid.indicator, item.TAT, item.invalidcount, item.overallcount, item.comment,item.dateclosed)),
      // let itemDetail = ["", "", `${item.facilityid.facility}`, `${moment(item.item.dateflagged).format('YYYY-MM-DD')}`, `${item.status}`, `${item.indicatorid.indicator}`, (item.TAT > 0) ? `${item.TAT}` : "", fillButtons(item.id, "", "", item.facilityid.facility, moment(item.item.dateflagged).format('YYYY-MM-DD'), item.status, item.indicatorid.indicator, item.TAT, item.invalidcount, item.overallcount, item.comment, (item.dateclosed !== null)?moment(item.dateclosed).format('YYYY-MM-DD'):"")]
      //let itemDetail = ["", "", `${item.flagged.facilityid.facility}`, `${item.flagged.status}`, `${moment(item.flagged.dateflagged).format('DD-MMM-YYYY')}`, `${item.flagged.indicatorid.indicator}`, (item.flagged.TAT > 0) ? `${item.flagged.TAT}` : "", fillButtons(item.id, "", "", item.facilityid.facility, moment(item.dateflagged).format('DD-MMM-YYYY'), item.status, item.indicatorid.indicator, item.TAT, item.invalidcount, item.overallcount, item.comment, item.dateclosed)]

      //let itemDetail = [`${item.region}`, `${item.district}`, `${item.facility}`, `${item.status}`, `${moment(item.dateflagged).format('DD-MMM-YYYY')}`, `${item.indicator}`, (item.TAT > 0) ? `${item.TAT}` : "", fillButtons(item.id, item.region, item.district, item.facility, moment(item.dateflagged).format('DD-MMM-YYYY'), item.status, item.indicator, item.TAT, item.invalidcount, item.overallcount, item.comment, item.dateclosed)]
      // id, facility, dateFlagged, status, indicator, tat, invalidcount, totalcount, comment, dateclosed,users
      let itemDetail = [`${item.facilityid.facility}`, `${item.status}`, `${moment(item.dateflagged).format('DD-MMM-YYYY')}`, `${item.indicatorid.indicator}`, (item.TAT > 0) ? `${item.TAT}` : "", fillButtons(`${item.id}`, item.facilityid.facility, moment(item.dateflagged).format('DD-MMM-YYYY'), item.status, item.indicatorid.indicator, item.TAT, item.invalidcount, item.overallcount, comments, item.dateclosed, users)]
      flaggedFacilities.push(itemDetail);
      counter = counter + 1
      // console.log(`items for table: ${itemDetail}`)
    })

    setTabledata3(
      {
        columns: [
          {
            label: 'id',
            field: 'id',
            sort: 'asc',
            width: 150
          },
          // {
          //   label: 'Region     ',
          //   field: 'region',
          //   sort: 'asc',
          //   width: 400
          // },
          // {
          //   label: 'District     ',
          //   field: 'district',
          //   sort: 'asc',
          //   width: 400
          // },
          {
            label: 'Health Facility     ',
            field: 'facility',
            sort: 'asc',
            width: 400
          },
          {
            label: 'Date Flagged',
            field: 'flaggedon',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Status',
            field: 'status',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Indicator',
            field: 'indicator',
            sort: 'asc',
            width: 100
          }
          ,
          {
            label: 'TAT',
            field: 'tat',
            sort: 'asc',
            width: 100
          }
          ,
          {
            label: 'Actions',
            field: 'actions',
            sort: 'asc',
            width: 100
          }
        ],
        rows: flaggedFacilities.map((prop, key) => {
          return {//`${item.facilityid.facility}`, `${item.status}`, `${moment(item.dateflagged).format('DD-MMM-YYYY')}`, `${item.indicatorid.indicator}`, (item.TAT > 0) ? `${item.TAT}` : "", fillButtons(item.facilityid.facility, moment(item.dateflagged).format('DD-MMM-YYYY'), item.status, item.indicatorid.indicator, item.TAT, item.invalidcount, item.overallcount, comments, item.dateclosed,users)
            id: key,
            facility: prop[0],
            status: prop[1],
            flaggedon: prop[2],
            indicator: prop[3],
            TAT: prop[4],
            actions: prop[5],
          };
        })
      }
    )
    setTabledatadata(flaggedFacilities.map((prop, key) => {
      return {
        id: key,
        facility: prop[2],
        status: prop[3],
        dateflagged: prop[4],
        indicator: prop[5],
        TAT: prop[6],
        actions: prop[7],
      };
    }))
  }

  const handleSearch = () => {

    let newfromDate = ""
    let newtoDate = ""
    
    if (fromDate === null || toDate === null) {
      warningWithConfirmMessage("Missing Dates", "Enter the From Date and To Date!");
    }else{
      if (moment(fromDate).format('YYYY-MM-DD') < moment(toDate).format('YYYY-MM-DD')) {
        newfromDate = moment(fromDate).format('YYYY-MM-DD')
        newtoDate = moment(toDate).format('YYYY-MM-DD')
      } else {
        newtoDate = moment(fromDate).format('YYYY-MM-DD')
        newfromDate = moment(toDate).format('YYYY-MM-DD')
      }

      DataHandlerService.getIndicatorstatsByDate(newfromDate, newtoDate)
      .then(response => {
      //  console.log("Indicator By Date : " + JSON.stringify(response.data))
        //  displayIndicatorstats(response.data)
        let indicatorStatsData = []
        let counter = 1
        response.data.forEach(item => {
          //  console.log("Item to render:" + JSON.stringify(item))
          if (counter < 13) {
            indicatorStatsData.push(displayIndicatorstats(item.indicator, item.invalidTestsNo, item.flaggedFacilityNo, item.facilities,item.metrics))
          }
          counter = counter + 1
        })

        setInsdicatorstats(indicatorStatsData)
      })
      .catch(error => handleError(error))


      // DataHandlerService.getAllFlaggedFacilitiesByDate(newfromDate, newtoDate)
      // .then(response => {
      //   console.log("From Date:"+response.data)
      //   setInSearchMode(true)
      //   displayAllFlaggedFacilities(response.data)
      // })
      // .catch(error => handleError(error))
    }
  }
  
  const fillButtons = (id, facility, dateFlagged, status, indicator, tat, invalidcount, totalcount, comment, dateclosed, users) => {
    return (<div className="actions-right">
      <Button
        onClick={() => editviewFlaggingDetails(id, facility, dateFlagged, status, indicator, tat, invalidcount, totalcount, comment, dateclosed)}
        color="warning"
        size="sm"
        className={classNames("btn-icon btn-link like", {
          "btn-neutral": id < 5,
        })}
      >
        <i className="tim-icons icon-pencil" />
      </Button> <Button
        onClick={() => viewFlaggingUserDetails(facility, indicator, users)}
        color="warning"
        size="sm"
        className={classNames("btn-icon btn-link like", {
          "btn-neutral": id < 5,
        })}
      >
        <i className="tim-icons icon-single-02" />
      </Button>{" "}
    </div>)
  }
  // const fillButtons = (id, region, district, facility, dateFlagged, status, indicator, tat, invalidcount, totalcount, comment, dateclosed) => {
  //   return (<div className="actions-right">
  //     <Button
  //       onClick={() => editviewFlaggingDetails(id, region, district, facility, dateFlagged, status, indicator, tat, invalidcount, totalcount, comment, dateclosed)}
  //       color="warning"
  //       size="sm"
  //       className={classNames("btn-icon btn-link like", {
  //         "btn-neutral": id < 5,
  //       })}
  //     >
  //       <i className="tim-icons icon-pencil" />
  //     </Button>{" "}
  //   </div>)
  // }
  const getIndicatorstats = () => {
    DataHandlerService.getIndicatorstats()
      .then(response => {
       // console.log("Indicator : " + JSON.stringify(response.data))
        //  displayIndicatorstats(response.data)
        let indicatorStatsData = []
        let counter = 1
        response.data.forEach(item => {
        //  console.log("Item to render:" + JSON.stringify(item))
          if (counter < 13) {
            indicatorStatsData.push(displayIndicatorstats(item.indicator, item.invalidTestsNo, item.flaggedFacilityNo, item.facilities, item.metrics))
          }
          counter = counter + 1
        })

        setInsdicatorstats(indicatorStatsData)
      })
      .catch(error => handleError(error))
  }
  const showInvalidDetails = (indicatortitle, facilities) => {
    let facilitiesTableData = []
    facilities.forEach(item => {
      let itemDetail = [`${item.FacilityName}`, `${item.invalidtests}`, `${item.totaltests}`, `${item.status}`]

      facilitiesTableData.push(itemDetail)
    })

    setTabledatadata2(facilitiesTableData.map((prop, key) => {
      return {
        data: [
          { className: "text-left", text: prop[0] },
          { className: "text-center", text: prop[1] },
          { className: "text-center", text: prop[2] },
          { className: "text-center", text: prop[3] }, {}
        ],
      };
    }))
    setindicatorText(indicatortitle)
    setOpen2(true)

  }
  const displayIndicatorstats = (indicatortext, invalidscount, facilitycount, facilities,metrics) => {
    if (invalidscount === 0) {
      return (
        <Col lg="2" md="6" style={{ 'margin-bottom': '80px', 'height': '100px', 'padding-left': '5px' }}>
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col xs="12" style={{ 'padding-right': '10px' }}>
                  <div className="numbers">
                    <p className="card-category">{indicatortext} </p>
                    <CardTitle tag="h3"><a href="#" >-</a></CardTitle>
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                No. of Health Facilities: <a href="#" >-</a>
              </div>
            </CardFooter>
          </Card>
        </Col>
      )
    }else{
      return (
        <Col lg="2" md="6" style={{ 'margin-bottom': '80px', 'height': '100px', 'padding-left': '5px' }}>
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col xs="12" style={{ 'padding-right': '10px' }}>
                  <div className="numbers">
                    <p className="card-category">{indicatortext} </p>
                    <CardTitle tag="h3"><a href="#" onClick={() => showInvalidDetails(indicatortext, facilities)} >{invalidscount}</a> <span style={{'fontSize':'1.0rem'}}>{metrics}</span></CardTitle>
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                No. of Health Facilities: <a href="#" onClick={() => showInvalidDetails(indicatortext, facilities)}>{facilitycount}</a>
              </div>
            </CardFooter>
          </Card>
        </Col>
      )
    }
   

  }

  const handleError = (error) => {
    if (error.response) {
      // Request made and server responded
      console.log(error.response.data);
      console.log("Error status" + error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.log(error.request);

    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }

  }

  const [alert, setAlert] = useState(null)
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [commentState, setCommentState] = React.useState("has-success");
  const [id, setId] = useState("")
  const [region, setRegion] = useState("")
  const [district, setDistrict] = useState("")
  const [facility, setFacility] = useState("")
  const [dateFlagged, setDateFlagged] = useState("")
  const [status, setStatus] = useState("")
  const [status2, setStatus2] = useState("")
  const [indicator, setIndicator] = useState("")
  const [tat, setTat] = useState("")
  const [invalidcount, setInvalidcount] = useState("")
  const [totalcount, setTotalcount] = useState("")
  const [dateclosed, setDateclosed] = useState("")
  const [comment, setComment] = useState("")
  const [commentOld, setCommentOld] = useState("")
  const hideAlert = () => { setAlert(null) }
  const handleClickOpen = () => {
    setOpen(true)

  }
  const handleClose = () => {
    setOpen(false)
    setOpen2(false)
    setOpen4(false)
    setTabledatadata4([])
    setTabledatadata2([])
    setId("")
    setRegion("")
    setDistrict("")
    setFacility("")
    setDateFlagged("")
    setStatus("")
    setStatus2("")
    setIndicator("")
    setTat("")
    setInvalidcount("")
    setTotalcount("")
    setCommentOld("")
    setComment("")
    setDateclosed("")
  }

  const editviewFlaggingDetails = (dbid, dbfacility, dbdateFlagged, dbstatus, dbindicator, dbtat, dbinvalidcount, dbtotalcount, dbcomment, dbdateclosed) => {
    let closedDate = (dbdateclosed !== null) ? moment(dbdateclosed).format('DD-MMM-YYYY') : null
    console.log('Total' + dbtotalcount)
    setOpen(true)
    setId(dbid)
    // setRegion(dbregion)
    // setDistrict(dbdistrict)
    setFacility(dbfacility)
    setDateFlagged(dbdateFlagged)
    setStatus(dbstatus)
    setStatus2(dbstatus)
    setIndicator(dbindicator)
    setTat(dbtat)
    setInvalidcount(dbinvalidcount)
    setTotalcount(dbtotalcount)
    setCommentOld(dbcomment)
    setDateclosed(dbdateclosed)
  }

  const viewFlaggingUserDetails = (facility, indicatortitle, users) => {
    let facilitiesUsersTableData = []
    users.forEach(item => {
      let itemDetail = [`${item.interviewerName}` + ' ' + `${item.interviewerName}`, `${item.invalidtests}`, `${item.totaltests}`, ""]

      facilitiesUsersTableData.push(itemDetail)
    })

    setTabledatadata4(facilitiesUsersTableData.map((prop, key) => {
      return {
        data: [
          { className: "text-left", text: prop[0] },
          { className: "text-center", text: prop[1] },
          { className: "text-center", text: prop[2] }, {}
        ],
      };
    }))
    setindicatorText(indicatortitle)
    setfacilityText(facility)
    setOpen4(true)
  }

  const handleSave = () => {
    if (comment === "") {
      setCommentState("has-danger");
    } else {
      let data = {
        comment: comment,
        status: status,
        id: id
      }
      DataHandlerService.updateFlaggedFacility(data)
        .then(response => {
          setrefreshTable(randomNum)
          successAlert()
          handleClose()
        })
        .catch(error => handleError(error))
    }
  }

  const change = (event, stateName, type) => {
    switch (type) {
      case "comment":
        setComment(event.target.value)
        // if ((event.target.value) !== "") {
        //   setCommentState("has-success");          
        // } else {
        //   setCommentState("has-danger");
        // }      
        break;
      default:
        break;
    }
  }

  const handleStatusChange = event => {
    // console.log("Selected" + event.target.value)
    setStatus(event.target.value)
  };

  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Updated"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      >
        Successfully Updated
      </ReactBSAlert>
    );
  };

  return (
    <>
      <div className="content">
        {alert}
        {/* the dialog of new/ edit of category */}
        <Dialog open={open2} onClose={handleClose} aria-labelledby="form-dialog-title" >
          <DialogTitle id="form-dialog-title">View/Edit</DialogTitle>
          <DialogContent style={{ 'width': '600px' }}>
            <h6>{indicatorText}</h6>
            <SortingTable
              thead={[
                { className: "text-left", text: "Health Facility" },
                { className: "text-left", text: "Invalid" },
                { className: "text-left", text: "Total" },
                { className: "text-left", text: "Status" },
              ]}
              tbody={tabledata2}
            /> </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="warning">
              Close
            </Button>

          </DialogActions>
        </Dialog>

        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
          <DialogTitle id="form-dialog-title">View/Edit</DialogTitle>
          <DialogContent style={{ 'width': '600px' }}>
            <Form className="form-horizontal">

              <Row><Label md="2" style={{ 'paddingTop': '0px' }}>Invalid Count</Label>
                <Col md="4">
                  <FormGroup>
                    <Input value={invalidcount} disabled={true} type="text" style={{ 'border': '1px solid #cad1d7', 'color': '#222a42' }} />
                  </FormGroup>
                </Col>
                <Label md="2" style={{ 'paddingTop': '0px' }}>Total count</Label>
                <Col md="4">
                  <FormGroup>
                    <Input value={totalcount} disabled={true} type="text" style={{ 'border': '1px solid #cad1d7', 'color': '#222a42' }} />
                  </FormGroup>
                </Col>

              </Row>
              <Row><Label md="3">Health Facility</Label>
                <Col md="9">
                  <FormGroup>
                    <Input value={facility} disabled={true} type="text" style={{ 'border': '1px solid #cad1d7', 'color': '#222a42' }} />
                  </FormGroup>
                </Col>
              </Row>
              <Row><Label md="3">Indicator</Label>
                <Col md="9">
                  <FormGroup>
                    <Input value={indicator} disabled={true} type="textarea" style={{ 'border': '1px solid #cad1d7', 'color': '#222a42' }} />
                  </FormGroup>
                </Col>
              </Row>
              <Row><Label md="3">Date Flagged</Label>
                <Col md="3">
                  <FormGroup>
                    <Input value={dateFlagged} disabled={true} type="text" style={{ 'border': '1px solid #cad1d7', 'color': '#222a42' }} />
                  </FormGroup>
                </Col>
                <Label md="3">Date Closed</Label>
                <Col md="3">
                  <FormGroup>
                    <Input value={dateclosed} disabled={true} type="text" style={{ 'border': '1px solid #cad1d7', 'color': '#222a42' }} />
                  </FormGroup>
                </Col>
              </Row>
              <Row><Label md="3">Comment</Label>
                <Col md="9">
                  <MDBDataTable
                    striped
                    bordered
                    entries={6}
                    hover
                    table-bordered
                    small
                    data={tabledata4}
                    entries={6}
                    entriesOptions={[6, 10, 20, 50, 100]}
                    style={{ 'border-top': '1px ' }}
                    fixed={false}
                  // maxHeight={360}                 
                  />
                  <FormGroup className="has-danger">
                    {commentOld}
                    {/* {(status2 === "Complete")=> */}
                    <Input onChange={(e) => change(e, "comment", "comment")} value={comment} type="textarea" style={{ 'border': '1px solid #cad1d7', 'color': '#222a42' }} />

                    {commentState === "has-danger" ? (
                      <label className="error">
                        Please enter a Comment to Save changes.
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row><Label md="3">Status</Label>
                <Col md="9">
                  <FormGroup check inline className="form-check-radio" onChange={handleStatusChange}>
                    <Label check>
                      <Input
                        defaultChecked={(status2 === "Pending") ? true : false}
                        defaultValue="Pending"
                        id="Pending"
                        name="status"
                        type="radio"
                      />
                      <span className="form-check-sign" />
                      Pending
                    </Label>
                  </FormGroup>
                  <FormGroup check inline className="form-check-radio" onChange={handleStatusChange}>
                    <Label check>
                      <Input
                        defaultChecked={(status === "On-going") ? true : false}
                        defaultValue="On-going"
                        id="On-going"
                        name="status"
                        type="radio"
                      />
                      <span className="form-check-sign" />
                      On-going
                    </Label>
                  </FormGroup>
                  <FormGroup check inline className="form-check-radio " onChange={handleStatusChange}>
                    <Label check>
                      <Input
                        defaultChecked={(status === "Complete") ? true : false}
                        defaultValue="Complete"
                        id="Complete"
                        name="status"
                        type="radio"
                      />
                      <span className="form-check-sign" />
                      Complete
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="warning">
              Cancel
            </Button>
            <Button onClick={handleSave} color="success" disabled={(status2 === "Complete") ? true : false}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={open4} onClose={handleClose} aria-labelledby="form-dialog-title" >
          {/* <DialogTitle id="form-dialog-title">View Users with Invalid entries</DialogTitle> */}
          <DialogContent style={{ 'width': '600px' }}>
            <h4>{facilityText}</h4>
            <h6>{indicatorText}</h6>
            <SortingTable
              thead={[
                { className: "text-left", text: "Interviewer Name" },
                { className: "text-left", text: "Invalid" },
                { className: "text-left", text: "Total" },
              ]}
              tbody={tabledata4}
            /> </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="warning">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Col md={8} className="ml-auto mr-auto">
          <h3 className="text-center" style={{ 'margin-bottom': '15px' }}> Performance Indicators  </h3>
        </Col> <Row>
          <Col lg="1" md="6" sm="3" style={{ 'padding-right': '0px', 'padding-left': '5px', 'padding-top': '24px', 'marginTop': '13px' }}>


          </Col>
          <Col lg="2" md="6" sm="3" >
          </Col>
          <Col lg="1" md="6" sm="3" style={{ 'padding-right': '0px', 'padding-left': '5px', 'padding-top': '14px', 'marginTop': '5px', 'textAlign': 'center' }}>
            <InputLabel className={classes.label, "d-inline"}>Filter By:</InputLabel>
          </Col>
          <Col lg="2" md="6" sm="3">
            <FormControl fullWidth>
              <Datetime
                dateFormat="DD-MMM-YYYY"
                timeFormat={false}
                isValidDate={valid}
                onChange={(date) => setFromDate(date)}
                inputProps={{ placeholder: "From Date:", style: { 'border': '0px', 'border-bottom': '1px solid #cad1d7', 'color': '#222a42' } }}
              />
            </FormControl>
          </Col>
          <Col lg="2" md="6" sm="3">
            <FormControl fullWidth >
              <Datetime
                dateFormat="DD-MMM-YYYY"
                timeFormat={false}
                isValidDate={valid}
                onChange={(date) => setToDate(date)}
                inputProps={{ placeholder: "To Date:", style: { 'border': '0px', 'border-bottom': '1px solid #cad1d7' } }}
              />
            </FormControl>
          </Col>
          <Col lg="1" md="6" sm="3" style={{ 'paddingRight': '0px' }}>
            <Button2 justIcon round style={{ 'background': '#f34' }} onClick={handleSearch}>
              <Search className={classesBtn.icons} className={classesBtn.marginRight} />
            </Button2>
            <Button2 color="info " justIcon round onClick={handleClear}>
              <Refresh className={classesBtn.icons} />
            </Button2>
          </Col>
          <Col lg="3" md="6" sm="3"></Col>
        </Row>
        <Row>
          {indicatorstats}
        </Row>
        <Row>
          <Col xs={12} md={12}>



            <Card>
              <CardHeader>
                <CardTitle tag="h4" style={{ 'margin-bottom': '0.05rem' }}>Health facilities to be supervised</CardTitle>


              </CardHeader>
              <CardBody style={{ 'paddingTop': '5px' }}>
                <Row>
                <Col lg="10" md="10" sm="12">
                <div>
                  <a href="#"  onClick={()=>getPendingFlaggedFacilities()}  style={{ "padding-left": "20px", "color": "#337ab7", "text-transform": "capitalize", "font-weight": "550" }}>
                    <i className="tim-icons icon-bulb-63" /> Pending
                    <Badge style={{ 'color': ' #ffffff', 'background-color': '#f34', 'border-radius': '25px' }}>
                      {pending}
                    </Badge>
                  </a>
                  <a href="#" onClick={()=>getOngoingFlaggedFacilities()} style={{ "padding-left": "20px", "color": "#337ab7", "text-transform": "capitalize", "font-weight": "550" }}>
                    <i className="tim-icons icon-chat-33" /> On-going <Badge style={{ 'color': ' #ffffff', 'background-color': '#ffa500', 'border-radius': '25px' }}>
                      {ongoing}
                    </Badge></a>
                  <a href="#" onClick={()=>getCompleteFlaggedFacilities()} style={{ "padding-left": "20px", "color": "#337ab7", "text-transform": "capitalize", "font-weight": "550" }}>
                    <i className="tim-icons icon-simple-remove" /> Complete <Badge style={{ 'color': ' #ffffff', 'background-color': '#0cbb06', 'border-radius': '25px' }}>
                      {complete}
                    </Badge></a>
                  <a href="#" onClick={()=>getInactiveFlaggedFacilities()} style={{ "padding-left": "20px", "color": "#337ab7", "text-transform": "capitalize", "font-weight": "550" }}>
                    <i className="tim-icons icon-bullet-list-67" /> archived <Badge style={{ 'color': ' #ffffff', 'background-color': '#adb5bd', 'border-radius': '25px' }}>
                      {inactive}
                    </Badge></a>
                </div>
                </Col>
                <Col lg="2" md="2" sm="12">
                  <CSVLink data={tabledata3.rows} className="float-right btn">Download</CSVLink>
                </Col>
                </Row>
                <MDBDataTable
                  striped
                  bordered
                  entries={6}
                  hover
                  table-bordered
                  small
                  data={tabledata3}
                  entries={6}
                  entriesOptions={[6, 10, 20, 50, 100]}
                  style={{ 'border-top': '1px ' }}
                  fixed={false}
                  maxHeight={360}
                />

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FlaggedFacilities;
