import Axios, { AxiosResponse, AxiosError } from "axios";
const dotenv = require('dotenv');
dotenv.config();
const API_URL = process.env.REACT_APP_API_URL;

class DataHandlerService {
  getDefaultHeader() {
    const token = 'Bearer ' + localStorage.getItem("token");
    return { headers: { "ContentType": "application/json", "Authorization": token } }
  }

  executeJWTAutheticationService() {
    return Axios.post(`${API_URL}/account/authenticate`, { 
      username: process.env.REACT_APP_API_DEFAULT_USERNAME, 
      password: process.env.REACT_APP_API_DEFAULT_PASSWORD 
    });
  }

  loginService(username, password) {
    return Axios.post(`${API_URL}/account/authenticate`, { username: username, password: password })
  }

  serverreachable() {
    return Axios.get(`${API_URL}/account/serverreachable`, this.getDefaultHeader())
  }

  executeTokenRefreshService() {
    let token = 'Bearer ' + localStorage.getItem("token")
    // console.log("Tokenrefresh:"+token)
    return Axios.post(`${API_URL}/account/refreshToken`, { headers: { "ContentType": "application/json", "Authorization": token }, username: localStorage.getItem("username"), reflab: localStorage.getItem("reflab"), type: localStorage.getItem("type") })
  }

  getRegions() {
    let token = 'Bearer ' + localStorage.getItem("token")
    //console.log("Token:"+token)

    return Axios.get(`${API_URL}/dashboard/getRegions`, this.getDefaultHeader())
  }
  
  /**********Daily Positive Tests ********/
  getDailyPositiveTestsAgg() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getDailyPositiveTestsAgg`, this.getDefaultHeader())
  }

  getpositiveTests() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getpositiveTests`, this.getDefaultHeader())
  }

  // getpositiveTestsByAgeGrp() {
  //   let token = 'Bearer ' + localStorage.getItem("token")

  //   return Axios.get(`${API_URL}/dashboard/getpositiveTestsByAgeGrp`, this.getDefaultHeader())
  // }

  getDistricts() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getDistricts`, this.getDefaultHeader())
  }

  getDistrictsByRegion(regionid) {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getDistrictsByRegion/${regionid}`, this.getDefaultHeader())
  }

  getHubs() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getHubs`, this.getDefaultHeader())
  }

  getFacilitiesByDistrict(districtid) {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getFacilitiesByDistrict/${districtid}`, this.getDefaultHeader())
  }

  getFacilitiesByRegion(regionid) {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getFacilitiesByRegion/${regionid}`, this.getDefaultHeader())
  }

  getFacilities() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getFacilities`, this.getDefaultHeader())
  }
  /*****************************Ebola**************************************/
  getEbolaSummaryAgg() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/ebola/getEbolaSummaryAgg`, this.getDefaultHeader())
  }
  //
  getDailyEbolaTestAgg() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/ebola/getDailyEbolaTestAgg`, this.getDefaultHeader())
  }

  getEbolaTestByDistrictAgg() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/ebola/getEbolaTestByDistrictAgg`, this.getDefaultHeader())
  }
  
  
/****************Tests Summary***********************/
  getGeneralTestsSummary(params) {
    return Axios.get(`${API_URL}/dashboard/fetchTestsAggregateSummary?${params}`, this.getDefaultHeader())
  }
  getTestsSummary() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getTestsSummary`, this.getDefaultHeader())
  }

  


  getTestsSummaryByDate(fromDate, toDate) {
    let token = 'Bearer ' + localStorage.getItem("token")

    // return Axios.get(`${API_URL}/dashboard/getTestsSummaryByDate/${fromDate}/${toDate}`, this.getDefaultHeader())
    return Axios.get(`${API_URL}/dashboard/fetchTestsAggregateSummary?fromDate=${fromDate}&toDate=${toDate}`, this.getDefaultHeader())
  }

  getTestsSummaryByDateByRegion(fromDate, toDate, regionID) {
    let token = 'Bearer ' + localStorage.getItem("token")

    // return Axios.get(`${API_URL}/dashboard/getTestsSummaryByDateByRegion/${fromDate}/${toDate}/${regionID}`, this.getDefaultHeader())
    return Axios.get(`${API_URL}/dashboard/fetchTestsAggregateSummary?fromDate=${fromDate}&toDate=${toDate}&regionID=${regionID}`, this.getDefaultHeader())
  }

  getTestsSummaryByDateByDIstrict(fromDate, toDate, districtid) {
    let token = 'Bearer ' + localStorage.getItem("token")

    // return Axios.get(`${API_URL}/dashboard/getTestsSummaryByDateByDIstrict/${fromDate}/${toDate}/${districtid}`, this.getDefaultHeader())
    return Axios.get(`${API_URL}/dashboard/fetchTestsAggregateSummary?fromDate=${fromDate}&toDate=${toDate}&districtID=${districtid}`, this.getDefaultHeader())
  }

  getTestsSummaryByDateByFacility(fromDate, toDate, facilityid) {
    let token = 'Bearer ' + localStorage.getItem("token")

    // return Axios.get(`${API_URL}/dashboard/getTestsSummaryByDateByFacility/${fromDate}/${toDate}/${facilityid}`, this.getDefaultHeader())
    return Axios.get(`${API_URL}/dashboard/fetchTestsAggregateSummary?fromDate=${fromDate}&toDate=${toDate}&facilityID=${facilityid}`, this.getDefaultHeader())
  }

  /****************Tests by Age grp***********************/
  fetchTestsByAgeGroup(params){
    return Axios.get(`${API_URL}/dashboard/fetchTestsByAgeGroup?${params}`, this.getDefaultHeader())
  }

  getTestAggByAgeGrp() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getTestAggByAgeGrp`, this.getDefaultHeader())
  }

  getTestAggByAgeGrpByDate(fromDate, toDate) {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getTestAggByAgeGrpByDate/${fromDate}/${toDate}`, this.getDefaultHeader())
  }

  getTestAggByAgeGrpByDateByRegion(fromDate, toDate, regionID) {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getTestAggByAgeGrpByDateByRegion/${fromDate}/${toDate}/${regionID}`, this.getDefaultHeader())
  }

  getTestAggByAgeGrpByDateByDistrict(fromDate, toDate, districtid) {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getTestAggByAgeGrpByDateByDistrict/${fromDate}/${toDate}/${districtid}`, this.getDefaultHeader())
  }

  getTestAggByAgeGrpByDateByFacility(fromDate, toDate, facilityid) {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getTestAggByAgeGrpByDateByFacility/${fromDate}/${toDate}/${facilityid}`, this.getDefaultHeader())
  }
/****************Tests by District (Table of district summary)***********************/
  fetchTestsByDistrict(params) {
    return Axios.get(`${API_URL}/dashboard/fetchTestsByDistrict?${params}`, this.getDefaultHeader())
  }

  getTestsByDistrict() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getTestsByDistrict`, this.getDefaultHeader())
  } 

  getTestByDistrictAggByDate(fromDate, toDate) {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getTestByDistrictAggByDate/${fromDate}/${toDate}`, this.getDefaultHeader())
  }

  getTestByDistrictAggByDateByRegion(fromDate, toDate, regionID) {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getTestByDistrictAggByDateByRegion/${fromDate}/${toDate}/${regionID}`, this.getDefaultHeader())
  }

  getTestByFacilityAggByDateByDistrict(fromDate, toDate, districtid) {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getTestByFacilityAggByDateByDistrict/${fromDate}/${toDate}/${districtid}`, this.getDefaultHeader())
  }


  getTestsByMonthAgg() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getTestsByMonthAgg`, this.getDefaultHeader())
  }

  fetchPositiveTestsByAgg(params) {
    return Axios.get(`${API_URL}/dashboard/fetchDailyPositiveTestsAgg?${params}`, this.getDefaultHeader())
  }

  getPositiveTestsByMonthAgg() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getPositiveTestsByMonthAgg`, this.getDefaultHeader())
  }

  getTestsByDistrictOrFacilityByTestMethod() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getTestsByDistrictOrFacilityByTestMethod`, this.getDefaultHeader())
  }

  getPositiveTestsByDistrictOrFacilityByTestMethodAgg() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getPositiveTestsByDistrictOrFacilityByTestMethodAgg`, this.getDefaultHeader())
  }

  getAllCovidPatients() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getAllCovidPatients`, this.getDefaultHeader())
  }

  getTestsByCategoryAgg() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getTestsByCategoryAgg`, this.getDefaultHeader())
  }

  getUserActivityPercentage() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getUserActivityPercentage`, { headers: { 'crossorigin': true, "ContentType": "application/json", "Authorization": token } })

  }

  getFacilityActivityPercentage() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getFacilityActivityPercentage`, this.getDefaultHeader())
  }

  getDailyPositiveTestsAggByDate(fromDate, toDate) {
    let token = 'Bearer ' + localStorage.getItem("token")
    return Axios.get(`${API_URL}/dashboard/getDailyPositiveTestsAggByDate/${fromDate}/${toDate}`, this.getDefaultHeader())
  }

  getDailyPositiveTestsAggByDateAndRegion(fromDate, toDate, regionID) {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getDailyPositiveTestsAggByDateAndRegion/${fromDate}/${toDate}/${regionID}`, this.getDefaultHeader())
  }

  getDailyPositiveTestsAggByDateAndDistrict(fromDate, toDate, districtid) {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getDailyPositiveTestsAggByDateAndDistrict/${fromDate}/${toDate}/${districtid}`, this.getDefaultHeader())
  }
  getDailyPositiveTestsAggByDateAndFacility(fromDate, toDate, facilityid) {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/dashboard/getDailyPositiveTestsAggByDateAndFacility/${fromDate}/${toDate}/${facilityid}`, this.getDefaultHeader())
  }

  fetchTestVaccinationByFacilities(params) {
    return Axios.get(`${API_URL}/dashboard/fetchTestVaccinationByFacility?${params}`, this.getDefaultHeader())
  }

  /**********Performance Indicator*****************/

  getFlaggedFacilities() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/indicator/getFlaggedFacilities`, this.getDefaultHeader())
  }

  getAllFlaggedFacilities() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/indicator/getAllFlaggedFacilities`, this.getDefaultHeader())
  }
  getAllFlaggedFacilitiesByDate(fromDate, toDate) {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/indicator/getAllFlaggedFacilitiesByDate/${fromDate}/${toDate}`, this.getDefaultHeader())
  }
  getFlaggedFacilitiesCount() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/indicator/getFlaggedFacilitiesCount`, this.getDefaultHeader())
  }
  getPendingFlaggedFacilities() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/indicator/getPendingFlaggedFacilities`, this.getDefaultHeader())
  }
  getOngoingFlaggedFacilities() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/indicator/getOngoingFlaggedFacilities`, this.getDefaultHeader())
  }
  getCompleteFlaggedFacilities() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/indicator/getCompleteFlaggedFacilities`, this.getDefaultHeader())
  }
  getInactiveFlaggedFacilities() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/indicator/getInactiveFlaggedFacilities`, this.getDefaultHeader())
  }

  getFacilityInvalidInfo() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/indicator/getFacilityInvalidInfo`, this.getDefaultHeader())
  }

  getFacilitiesStock() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/stock/getFacilitiesStock`, this.getDefaultHeader())
  }

  getFacilityIndicatorstats() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/indicator/getFacilityIndicatorstats`, this.getDefaultHeader())
  }

  getIndicatorstats() {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/indicator/getIndicatorstats`, this.getDefaultHeader())
  }

  getIndicatorstatsByDate(fromDate, toDate) {
    let token = 'Bearer ' + localStorage.getItem("token")

    return Axios.get(`${API_URL}/indicator/getIndicatorstatsByDate/${fromDate}/${toDate}`, this.getDefaultHeader())
  }
  updateFlaggedFacility(data) {
    let token = 'Bearer ' + localStorage.getItem("token")
    let username = localStorage.getItem('username')

    //  console.log( `username in session storage: ${username}header ${token}`)
    return Axios.post(`${API_URL}/indicator/saveFlaggingUpdate/${username}`, data, { headers: { "Authorization": token } })
  }

  /**********Stock *****************/
  // getFacilitiesStock() {
  //   let token = 'Bearer ' + localStorage.getItem("token")

  //   return Axios.get(`${API_URL}/stock/getFacilitiesStock`, this.getDefaultHeader())
  // }

  executeJWTAutheticationService2() {

    return Axios.post(`${API_URL}/account/authenticate`, { username: "CPHL", password: "T4Vd5+z-RuhH-AAz" })
  }

}

export default new DataHandlerService()
