/*!

=========================================================
* COVID-19 Dashboard- v1.2.0
=========================================================

* Product Page: http://cphl.go.ug/covid19dashboard
* Copyright 2020 Namutebi Laiton http://cphl.go.ug

* Coded by Namutebi Laiton

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classNames from "classnames";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";

import ReactTable from "components/ReactTable/ReactTableCov.js";

const dataTable = [
  ["eLIF", "user: Janet cannot log into eLif", "05-OCT-2021", "On-going","","the account is being verified"],
  ["RDS", "Cannot access results of Patient Akello Pauline Lab ID : SRRH-123455", "06-OCT-2021", "Complete","06-OCT-2021","Resolved with facility"],
  
  
];

const ReactTables = () => {
  const [data, setData] = React.useState(
    dataTable.map((prop, key) => {
      return {
        id: key,
        category: prop[0],
        detail: prop[1],
        date: prop[2],
        recieved: prop[3],
        closedate: prop[4],
        comment: prop[5],
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a like kind of action */}
            {/* <Button
              onClick={() => {
                let obj = data.find((o) => o.id === key);
                alert(
                  "You've clicked LIKE button on \n{ \nName: " +
                    obj.category +
                    ", \Category: " +
                    obj.detail +
                    ", \Detail: " +
                    obj.date +
                    ", \nDate: " +
                    obj.age +
                    "\n}."
                );
              }}
              color="info"
              size="sm"
              className={classNames("btn-icon btn-link like", {
                "btn-neutral": key < 5,
              })}
            >
              <i className="tim-icons icon-heart-2" />
            </Button> */}
            {" "}
            {/* use this button to add a edit kind of action */}
            <Button
              onClick={() => {
                let obj = data.find((o) => o.id === key);
                alert(
                  "Query Detail  \n\nCategory: " +
                  obj.category +
                  ", \n\nDetail: " +
                  obj.detail +
                  ", \n\nDate: " +
                  obj.date +
                  ", \n\nStatus: " +
                  obj.recieved +
                  ", \n\nDate Closed: " +
                  obj.closedate +
                  ", \n\nComment: " +
                  obj.comment +
                  ", \n " +
                    "\n"
                );
              }}
              color="warning"
              size="sm"
              className={classNames("btn-icon btn-link like", {
                "btn-neutral": key < 5,
              })}
            >
              <i className="tim-icons icon-pencil" />
            </Button>{" "}
            {/* use this button to remove the data row */}
            {/* <Button
              onClick={() => {
                var newdata = data;
                newdata.find((o, i) => {
                  if (o.id === key) {
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    data.splice(i, 1);
                    console.log(data);
                    return true;
                  }
                  return false;
                });
                setData(newdata);
              }}
              color="danger"
              size="sm"
              className={classNames("btn-icon btn-link like", {
                "btn-neutral": key < 5,
              })}
            >
              <i className="tim-icons icon-simple-remove" />
            </Button> */}{" "}
          </div>
        ),
      };
    })
  );
  return (
    <>
      <div className="content">
        <Col md={8} className="ml-auto mr-auto">
          <h2 className="text-center">Soroti RRH - Queries</h2>
          </Col>
        <Row className="mt-5">
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Queries</CardTitle>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={data}
                  filterable
                  resizable={false}
                  columns={[
                    {
                      Header: "Category",
                      accessor: "category",
                    },
                    {
                      Header: "Detail",
                      accessor: "detail",
                    },
                    {
                      Header: "Date",
                      accessor: "date",
                    },
                    {
                      Header: "Status",
                      accessor: "recieved",
                    },
                    {
                      Header: "Date Closed",
                      accessor: "closedate",
                    },{
                      Header: "Comment",
                      accessor: "comment",
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReactTables;
