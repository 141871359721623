/*!

=========================================================
* COVID-19 Dashboard- v1.2.0
=========================================================

* Product Page: http://cphl.go.ug/covid19dashboard
* Copyright 2020 Namutebi Laiton http://cphl.go.ug

* Coded by Namutebi Laiton

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Main.js";
import Study from "views/Study.js";
import Indicators from "views/FlaggedFacilities.js";
import Performance from "views/Facilityperformance.js";
import Queries from "views/Queries.js";
import AllQueries from "views/AllQueries";
import Stock from "views/Logistics.js";

const routes = [
{
    path: "/queries",
    name: "Queries",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Queries,
    layout: "/",
  },{
    path: "/allqueries",
    name: "AllQueries",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: AllQueries,
    layout: "/",
  },
  {
    path: "/indicators",
    name: "Indicators",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Indicators,
    layout: "/",
  },  
  {
    path: "/facilityperformance",
    name: "Performance",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Performance,
    layout: "/",
  },
  {
    path: "/facilitystock",
    name: "Stock",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Stock,
    layout: "/",
  },  
  {
    path: "/study",
    name: "Study",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Study,
    layout: "/",
  },
  {
    path: "/",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/",
  },
];

export default routes;
